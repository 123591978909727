import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import DefaultLayout from "@/components/layout";
import Loader from "@/components/shared/Loader";
import Toast from "@/components/shared/Toast";
import useAuth from "@/hooks/useAuth";
//mui
import platformRoutes from "@/routes";
import { SourceDataGetAndStore } from "@/store/actions/dataSourceActions";
import { SubscriptionDataGetAndStore } from "@/store/actions/subscriptionAction";
import { setToast } from "@/store/slices/toastSlice";

const RouterProvider = function () {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toaster.toast);
  const { boot } = useIntercom();

  const { isLoading, error } = useAuth();

  const subscriptionLoading = useSelector(
    (state) => state.subscription.loading,
  );

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(SubscriptionDataGetAndStore(accessToken));
      dispatch(SourceDataGetAndStore(accessToken));
    };

    if (!isMounted) {
      return;
    }

    getData();

    return () => {
      isMounted = false;
    };
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      // When user info is available, boot Intercom
      boot({
        name: user.name,
        email: user.email,
        user_id: user.id,
      });
    }
  }, [user, boot]);

  if (isLoading || subscriptionLoading) {
    return <Loader message={"Loading data"} />;
  }

  if (error) {
    return <Box>Oops... {error.message}</Box>;
  }

  return (
    <Box>
      {toast && toast.message && (
        <Toast
          autoHideDuration={2000}
          onCloseCallback={() => {
            dispatch(setToast(null));
          }}
          title={toast.message}
          severity={toast.toasterColor}
        />
      )}
      <DefaultLayout>
        <Routes>
          {platformRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.routePath}
              element={route.component}
            />
          ))}
        </Routes>
      </DefaultLayout>
    </Box>
  );
};

export default RouterProvider;
