import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import {
  addModule,
  deleteModule,
  getModules,
  updateModule,
} from "@/api/moduleApi";
import { addNote } from "@/api/notesApi";
import ModuleEmptyState from "@/components/modules/StudentModules/EmptyScreen";
import ListModules from "@/components/modules/StudentModules/ListModules";
import Loader from "@/components/shared/Loader";
import AddNoteDialog from "@/components/shared/Modals/AddNoteDialog";
import ConfirmationDialog from "@/components/shared/Modals/ConfirmModal";
import ShareDialog from "@/components/shared/Modals/ShareModal";
import { goToRoute } from "@/store/actions/navigationActions";
import { setToast } from "@/store/slices/toastSlice";

const EditModuleDialog = lazy(
  () => import("@/components/shared/Modals/EditModuleDialog"),
);
const AddModuleDialog = lazy(
  () => import("@/components/shared/Modals/AddModuleDialog"),
);

const StudentModule = () => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addModuleLoading, setAddModuleLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedModuleDetail, setSelectedModuleDetail] = useState(null);
  const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const fetchModules = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        getModules(accessToken)
          .then((data) => {
            setModules(data.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Failed to fetch knowledge bases:", error);
            setLoading(false);
          });
      } catch (error) {
        console.error("Failed to fetch Modules:", error);
      }
    };
    fetchModules();
  }, [getAccessTokenSilently]);

  // delete module confirmed
  const handleDeleteConfirm = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await deleteModule(accessToken, deleteItemId);
      // Update the modules state by filtering out the deleted module
      setModules((prevModules) =>
        prevModules.filter((module) => module._id !== deleteItemId),
      );

      dispatch(
        setToast({
          message: "Module deleted successfully.",
          toasterColor: "success",
        }),
      );
    } catch (error) {
      dispatch(
        setToast({
          message:
            error.response.data.error.message || "Failed to create the module",
          toasterColor: "error",
        }),
      );
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  // Handler to view a module
  const handleViewModuleClick = (moduleId) => {
    dispatch(goToRoute(`/module/${moduleId}/d`));
  };

  // Handler to delete a module
  const handleDeleteClick = (moduleId) => {
    setDeleteItemId(moduleId);
    setDeleteDialogOpen(true);
  };

  // Handler to chat with tutor related to a module
  const handleChatClick = (moduleId) => {
    dispatch(goToRoute(`/module/${moduleId}/c`));
  };

  const handleAddModuleClick = () => {
    setAddDialogOpen(true);
  };

  const handleAddModule = async (module) => {
    setAddModuleLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const payload = {
        name: module.name,
        description: module.description || "",
      };

      const response = await addModule(accessToken, payload);

      dispatch(
        setToast({
          message: "Module Created successfully.",
          toasterColor: "success",
        }),
      );

      setAddDialogOpen(false);

      dispatch(goToRoute(`/module/${response.data._id}/d`));
    } catch (error) {
      dispatch(
        setToast({
          message:
            error.response.data.error.message || "Failed to create the module",
          toasterColor: "error",
        }),
      );
    } finally {
      setAddModuleLoading(false);
      setAddDialogOpen(false);
    }
  };

  const handleRenameClick = (module) => {
    setSelectedModuleDetail(module);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  const handleEditModule = useCallback(
    async (title, description) => {
      try {
        const payload = {
          name: title,
          description: description,
        };
        const accessToken = await getAccessTokenSilently();
        const response = await updateModule(
          accessToken,
          selectedModuleDetail._id,
          payload,
        );

        setModules((prevModules) => {
          return prevModules.map((mod) =>
            mod._id === selectedModuleDetail._id ? response.data : mod,
          );
        });

        setEditDialogOpen(false);

        dispatch(
          setToast({
            message: "Module updated successfully.",
            toasterColor: "success",
          }),
        );
      } catch (error) {
        dispatch(
          setToast({
            message:
              error.response.data.error.message ||
              "Failed to create the module",
            toasterColor: "error",
          }),
        );
      } finally {
        setEditDialogOpen(false);
      }
    },
    [getAccessTokenSilently, selectedModuleDetail, dispatch],
  );

  const handleNewNoteClick = (module) => {
    setSelectedModuleDetail(module);
    setAddNoteDialogOpen(true);
  };

  const handleShareClick = (module) => {
    setSelectedModuleDetail(module);
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  const handleAddNote = useCallback(
    async (note) => {
      setAddNoteLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const payload = {
          title: note.name,
          module_id: selectedModuleDetail._id,
        };

        const response = await addNote(accessToken, payload);

        dispatch(
          setToast({
            message: "Note Created successfully.",
            toasterColor: "success",
          }),
        );

        setAddNoteDialogOpen(false);

        dispatch(
          goToRoute(
            `/module/${selectedModuleDetail._id}/n/${response.data._id}`,
          ),
        );
      } catch (error) {
        console.log(error);
        dispatch(
          setToast({
            message:
              error.response.data.error.message ||
              "Failed to create the module",
            toasterColor: "error",
          }),
        );
      } finally {
        setAddNoteLoading(false);
        setAddNoteDialogOpen(false);
      }
    },
    [getAccessTokenSilently, selectedModuleDetail],
  );

  if (loading) {
    return <Loader message="Loading modules" />;
  }

  return (
    <>
      {modules.length > 0 ? (
        <>
          <ListModules
            items={modules}
            handleAddModule={handleAddModuleClick}
            handleChatClick={handleChatClick}
            handleDeleteModuleClick={handleDeleteClick}
            handleViewModuleClick={handleViewModuleClick}
            handleRenameClick={handleRenameClick}
            handleNewNoteClick={handleNewNoteClick}
            handleShareClick={handleShareClick}
          />

          <ConfirmationDialog
            open={deleteDialogOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={() => {
              setDeleteDialogOpen(false);
            }}
            title="Confirm Delete"
            description="Are you sure you want to remove this Module? "
            secondaryText="This action cannot be undone. This will remove the module and any chats, materials related to it."
            loading={loading}
          />
        </>
      ) : (
        <ModuleEmptyState handleClick={handleAddModuleClick} />
      )}
      <Suspense fallback={<Loader message="Loading dialog..." />}>
        <AddModuleDialog
          open={addDialogOpen}
          handleAddModule={handleAddModule}
          handleClose={() => setAddDialogOpen(false)}
          loading={addModuleLoading}
        />
      </Suspense>
      <Suspense fallback={<Loader message="Loading dialog..." />}>
        <EditModuleDialog
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          onSubmit={handleEditModule}
          initialTitle={selectedModuleDetail?.name}
          initialDescription={selectedModuleDetail?.description}
        />
      </Suspense>
      <Suspense fallback={<Loader message="Loading dialog..." />}>
        {selectedModuleDetail && (
          <AddNoteDialog
            open={addNoteDialogOpen}
            handleAddNote={handleAddNote}
            module={selectedModuleDetail}
            handleClose={() => setAddNoteDialogOpen(false)}
            loading={addNoteLoading}
          />
        )}
      </Suspense>
      <Suspense fallback={<Loader message="Loading Share dialog..." />}>
        <ShareDialog
          open={shareDialogOpen}
          onClose={handleCloseShareDialog}
          title={selectedModuleDetail?.name}
          sharedList={selectedModuleDetail?.shared_with_details}
          module={selectedModuleDetail}
          owner={selectedModuleDetail?.user_id === user.sub}
        />
      </Suspense>
    </>
  );
};

export default StudentModule;
