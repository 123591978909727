// Section1.js
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import getIcon from "@/utils/get-icon";

export default function BaseSection({
  actionsList,
  shouldHighlight,
  open,
  onClickAction,
}) {
  const theme = useTheme();
  return (
    <List sx={{ marginTop: 3 }}>
      {actionsList.map((item, index) => (
        <Tooltip
          title={open ? "" : item.label}
          placement="right"
          key={item.label}
        >
          <ListItemButton
            key={index}
            onClick={() => onClickAction(item)}
            sx={{
              borderRadius: "0.75rem",
              margin: (theme) => theme.spacing(1),
              marginBottom: (theme) => theme.spacing(3),
              paddingTop: "0.2rem",
              paddingBottom: "0.2rem",
              paddingLeft: "0.5rem",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
              ...(shouldHighlight(item)
                ? { backgroundColor: theme.palette.primary.light }
                : {}),
            }}
          >
            <ListItemIcon
              sx={{
                color: shouldHighlight(item)
                  ? (theme) => theme.palette.primary.main
                  : "inherit",
                minWidth: "2.5rem",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: 24,
                  height: 24,
                  borderRadius: 2,
                }}
              >
                {getIcon(item.icon, "inherit", "24px", "", 400)}
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontWeight: shouldHighlight(item) ? "bold" : "medium",
                color: shouldHighlight(item)
                  ? (theme) => theme.palette.primary.main
                  : "inherit",
              }}
            />
          </ListItemButton>
        </Tooltip>
      ))}
    </List>
  );
}
