import React from "react";

// material
import { Box, Typography, useTheme } from "@mui/material";
import { hatch } from "ldrs";

hatch.register();

export default function Loader({ size = "24", message }) {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      height="80vh"
      display="flex"
      alignItems={"center"}
      justifyContent="center"
    >
      <Box
        position="relative"
        display="flex"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.primary.main,
          zIndex: "3",
          position: "absolute",
        }}
      >
        <l-hatch size={size} stroke="4" speed="3.5" color="black"></l-hatch>
        {message ? (
          <Typography variant="subtitle2" fontSize={14} fontWeight={600} mt={2}>
            {message}
          </Typography>
        ) : (
          ""
        )}
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
      </Box>
    </Box>
  );
}
