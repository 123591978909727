import React from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

const NotesEmptyState = ({ handleClick }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={4}
      textAlign="center"
      p={2}
      sx={{
        maxWidth: "100%",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px", // Limit the max width for large screens
          mx: "auto",
          px: { xs: 2, sm: 4 }, // Add padding on smaller screens
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: "1.25rem" }, // Adjust font size based on screen size
          }}
        >
          No Notes
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          gutterBottom
          sx={{
            fontSize: { xs: "0.875rem", sm: "1rem" }, // Adjust font size based on screen size
          }}
        >
          You haven`&apos;t created any notes yet.
        </Typography>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          color="primary"
          variant="contained"
          sx={{
            mt: 2,
            py: { xs: 1, sm: 1 },
            fontSize: { xs: "small", sm: "medium" },
          }}
          onClick={handleClick}
        >
          Add Note
        </Button>
      </Box>
    </Box>
  );
};

NotesEmptyState.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default NotesEmptyState;
