import { getSubscription } from "@/api/subscription";
import { updateSubscriptionData } from "@/store/slices/subscriptionSlice";

import { goToRoute } from "./navigationActions";

export const SubscriptionDataGetAndStore = (accessToken) => {
  return (dispatch) => {
    accessToken
      ? getSubscription(accessToken)
          .then((res) => {
            if (res.data) {
              dispatch(updateSubscriptionData(res.data));
            }
          })
          .catch((e) => {
            console.log(e);
          })
      : dispatch(goToRoute("/login"));
  };
};
