import React from "react";

import ReactMarkdown from "react-markdown";

import Citation from "./Citations";

const MarkdownContent = ({ content, citations, dialogRef }) => {
  const processChildren = (children, citations, dialogRef) => {
    const result = [];

    React.Children.forEach(children, (child) => {
      if (typeof child === "string") {
        // Split the string by the citation placeholder regex
        const parts = child.split(/(<<CITATION_\d+>>)/g);
        parts.forEach((part, index) => {
          const match = part.match(/<<CITATION_(\d+)>>/);
          if (match) {
            const citationNumber = match[1];
            result.push(
              <Citation
                key={`citation-${citationNumber}-${index}`}
                number={citationNumber}
                citations={citations}
                dialogRef={dialogRef}
              />,
            );
          } else {
            result.push(part);
          }
        });
      } else if (React.isValidElement(child)) {
        // Recursively process the child element's children
        const processedChild = React.cloneElement(child, {
          ...child.props,
          children: processChildren(child.props.children, citations, dialogRef),
        });
        result.push(processedChild);
      } else {
        // For other types (e.g., numbers), add them directly
        result.push(child);
      }
    });
    return result;
  };

  /* eslint-disable no-unused-vars */
  const components = {
    // Paragraph
    p: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <p>{processedChildren}</p>;
    },
    // List Item
    li: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <li>{processedChildren}</li>;
    },
    // Headings
    h1: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <h1>{processedChildren}</h1>;
    },
    h2: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <h2>{processedChildren}</h2>;
    },
    h3: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <h3>{processedChildren}</h3>;
    },
    h4: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <h4>{processedChildren}</h4>;
    },
    h5: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <h5>{processedChildren}</h5>;
    },
    h6: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <h6>{processedChildren}</h6>;
    },
    // Blockquote
    blockquote: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <blockquote>{processedChildren}</blockquote>;
    },
    // Links
    a: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          {processedChildren}
        </a>
      );
    },
    // Bold Text
    strong: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <strong>{processedChildren}</strong>;
    },
    // Italic Text
    em: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <em>{processedChildren}</em>;
    },
    // Inline Code
    code: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <code>{processedChildren}</code>;
    },
    // Ordered and Unordered Lists
    ul: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <ul>{processedChildren}</ul>;
    },
    ol: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <ol>{processedChildren}</ol>;
    },
    // Preformatted Text
    pre: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <pre>{processedChildren}</pre>;
    },
    // Span for inline elements
    span: ({ node, ...props }) => {
      const processedChildren = processChildren(
        props.children,
        citations,
        dialogRef,
      );
      return <span>{processedChildren}</span>;
    },
    br: () => <br />,
  };

  return (
    <ReactMarkdown
      className="reactMarkDown"
      skipHtml={true}
      components={components}
    >
      {content.replace(/\n/g, "  \n")}
    </ReactMarkdown>
  );
};

export default MarkdownContent;
