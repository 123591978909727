import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import GeneralCard from "@/components/shared/GeneralCard";
import { getRandomLightColor } from "@/utils/common-utils";

const ListNoteBody = ({ items, user, handleViewClick, handleDeleteClick }) => {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={4}>
        {items.map((note) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={note._id}>
            <GeneralCard
              title={note.title}
              user={user}
              ownerId={note.owner_id}
              mediaBackgroundColor={getRandomLightColor(theme)}
              createdAt={new Date(note.created_at).toLocaleDateString()}
              createdByDetail={note.created_by_details}
              onCardClick={() => handleViewClick(note._id)}
              additionalChips={[]}
              menuItems={[
                {
                  label: "Delete Note",
                  icon: (
                    <DeleteOutlineRoundedIcon
                      fontSize="medium"
                      style={{ color: "red" }}
                    />
                  ),
                  onClick: () => handleDeleteClick(note._id),
                  hidden: note.created_by_details.user_id !== user.sub,
                },
              ]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ListNoteBody.propTypes = {
  items: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleViewClick: PropTypes.func.isRequired,
};

export default ListNoteBody;
