// prop-constants.js
import React from "react";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const getPricingPlanProps = (subscription_key) => {
  const plans = [
    {
      name: "Free",
      key: "free",
      price: "£0/month",
      currentPlan: subscription_key === "free",
      disabled: false,
      buttonText: "Continue with Free",
      buttonVariant: "outlined",
      features: [
        "Assistance with writing, problem solving and more",
        "Access to GPT-3.5",
        "Limited access to GPT-4",
        "Limited access to advanced data analysis, file uploads, vision, web browsing, and custom GPTs",
      ],
    },
    {
      name: "Premium",
      key: "premium",
      price: "£20/month",
      currentPlan: subscription_key === "premium",
      disabled: false,
      buttonText: "Upgrade to Premium",
      buttonVariant: "contained",
      buttonIcon: <AutoAwesomeIcon />,
      features: [
        "Early access to new features",
        "Access to GPT-4, GPT-4.0, GPT-3.5",
        "Up to 5x more messages for GPT-4.0",
        "Access to advanced data analysis, file uploads, vision, and web browsing",
        "DALL-E image generation",
        "Create and use custom GPTs",
      ],
    },
  ];

  return plans;
};

export default getPricingPlanProps;
