import React from "react";

import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { quantum, dotWave } from "ldrs";
import PropTypes from "prop-types";

// Register loaders
dotWave.register();
quantum.register();

export const TypingIndicator = ({
  marginTop = 4,
  avatarSrc = "/lrnd-chat-icon.png",
  avatarSize = 32,
  avatarBgColor,
  message = "",
  messageFontSize = "0.875rem",
  messageVariant = "body1",
  messageFontWeight = "bold",
  loaderType = "quantum",
  loaderSize = 45,
  loaderSpeed = 1.75,
  loaderColor,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: marginTop,
        alignItems: "center",
      }}
      gap={1}
    >
      <Avatar
        sx={{
          width: avatarSize,
          height: avatarSize,
          fontSize: "14px",
          backgroundColor: avatarBgColor || theme.palette.primary.main,
          padding: "4px",
        }}
        src={avatarSrc}
        alt={"Bot"}
      />
      {message && (
        <Typography
          variant={messageVariant}
          fontSize={messageFontSize}
          color="textPrimary"
          fontWeight={messageFontWeight}
        >
          {message}
        </Typography>
      )}
      {loaderType === "dotWave" ? (
        <l-dot-wave
          size={loaderSize.toString()}
          speed={loaderSpeed.toString()}
          color={loaderColor || "black"}
        ></l-dot-wave>
      ) : (
        <l-quantum
          size={loaderSize.toString()}
          speed={loaderSpeed.toString()}
          color={loaderColor || theme.palette.primary.main}
        ></l-quantum>
      )}
    </Box>
  );
};

TypingIndicator.propTypes = {
  marginTop: PropTypes.number,
  avatarSrc: PropTypes.string,
  avatarSize: PropTypes.number,
  avatarBgColor: PropTypes.string,
  message: PropTypes.string,
  messageFontSize: PropTypes.string,
  messageVariant: PropTypes.string,
  messageFontWeight: PropTypes.string,
  loaderType: PropTypes.oneOf(["dotWave", "quantum"]),
  loaderSize: PropTypes.number,
  loaderSpeed: PropTypes.number,
  loaderColor: PropTypes.string,
};

TypingIndicator.defaultProps = {
  marginTop: 8,
  avatarSrc: "/lrnd-chat-icon.png",
  avatarSize: 32,
  loaderType: "quantum",
  loaderSize: 45,
  loaderSpeed: 1.75,
  message: "",
};
