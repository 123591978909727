import { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Container,
  Typography,
  Grid,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";

import Loader from "@/components/shared/Loader";
import theme from "@/utils/theme";

import BillingDetails from "./partials/BillingDetails";
import ProfileDetails from "./partials/ProfileDetails";
import ProfileTabs from "./partials/ProfileTabs";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState(0); // To manage the selected tab
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const subscription = useSelector((state) => state.subscription.subscription);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getClaims = async () => {
      const claims = await getIdTokenClaims();
      setDetails(claims);
      setLoading(false);
    };
    getClaims();
  }, [getIdTokenClaims]);

  if (!isAuthenticated) {
    return (
      <Typography variant="h6">
        You need to log in to view this page.
      </Typography>
    );
  }

  if (loading) {
    return <Loader message="Loading data" />;
  }

  return (
    <Container>
      <Toolbar sx={{ marginBottom: 4 }} paddingLeft={0} paddingRight={0}>
        <SettingsOutlinedIcon />
        <Typography variant="h4" marginLeft={2}>
          Settings
        </Typography>
      </Toolbar>
      <Grid container spacing={3}>
        {/* Tabs List */}
        <Grid item xs={12} sm={2} md={2}>
          <ProfileTabs
            selectedTab={selectedTab}
            handleTabChange={setSelectedTab}
            isMobile={isMobile}
          />
        </Grid>

        {/* Content Area */}
        <Grid item xs={12} sm={10} md={10}>
          {selectedTab === 0 ? (
            <ProfileDetails details={details} />
          ) : (
            <BillingDetails subscription={subscription} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
