import { useState } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";

import sources from "@/props-constants/ExpandingInputProps";

const ExpandingTextInput = ({
  userInput = "",
  onInputChange,
  onSend,
  setQaType,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSource, setSelectedSource] = useState(sources[0].key);

  const isMultiLine = userInput.split("\n").length > 1 || userInput.length > 80;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSourceSelect = (sourceKey) => {
    setSelectedSource(sourceKey);
    setQaType(sourceKey);
    handleClose();
  };

  const handleInputChange = (event) => {
    onInputChange(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    } else if (event.key === "Enter" && event.shiftKey) {
      onInputChange(userInput);
    }
  };

  const handleSend = () => {
    if (userInput.trim()) {
      onSend(userInput);
      onInputChange("");
    }
  };

  const getSelectedSourceDetails = () => {
    return (
      sources.find((source) => source.key === selectedSource) || sources[0]
    );
  };

  const renderSourceButton = (isMultiLine) => {
    const selectedSourceDetails = getSelectedSourceDetails();
    return (
      <Button
        edge="end"
        color="primary"
        onClick={handleClick}
        startIcon={selectedSourceDetails.icon}
        endIcon={
          <KeyboardArrowDownOutlinedIcon
            sx={{ width: "0.75em", height: "0.75em" }}
          />
        }
        fontWeight="medium"
        fontFamily='ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"'
        sx={{
          fontSize: isMultiLine ? "14px" : "12px",
          borderRadius: 2,
          padding: isMultiLine ? 1 : 0.5,
          mr: isMultiLine ? 0 : 0.2,
          "&:hover": {
            borderRadius: 2,
          },
        }}
      >
        {selectedSourceDetails.buttonText}
      </Button>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMultiLine ? "column" : "row",
        alignItems: "center",
        maxWidth: "100%",
        borderRadius: "24px",
        backgroundColor: "#fff",
        position: "relative",
        border:
          isFocused || userInput ? "2px solid #8fb2ff" : "1px solid #e0e0e0",
        transition: "border 0.1s",
      }}
    >
      <TextField
        value={userInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder="Ask anything..."
        multiline
        maxRows={10}
        variant="outlined"
        fullWidth
        size="small"
        InputProps={{
          sx: {
            fontSize: "14px",
            paddingRight: "16px",
          },
          endAdornment: !isMultiLine && (
            <InputAdornment position="end">
              {renderSourceButton(false)}
              <IconButton
                edge="end"
                color="primary"
                disabled={userInput === ""}
                onClick={handleSend}
                disableRipple
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "240px",
          minHeight: "1em",
          "& fieldset": { border: "none" },
        }}
      />
      {isMultiLine && (
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            width: "100%",
            justifyContent: "space-between",
            px: 2,
            mb: 0.5,
          }}
        >
          {renderSourceButton(true)}
          <IconButton
            edge="end"
            color="primary"
            disabled={userInput === ""}
            onClick={handleSend}
            disableRipple
          >
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: "220px",
            mt: 1,
            boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
            borderRadius: "8px",
          },
        }}
      >
        <List sx={{ py: 2, mx: 1 }}>
          {sources.map((source) => (
            <ListItem
              key={source.key}
              button
              onClick={() => handleSourceSelect(source.key)}
              sx={{
                py: 0,
                px: 0.5,
                mb: 1,
                mt: 1,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.04)",
                  borderRadius: 1,
                },
                backgroundColor:
                  selectedSource === source.key
                    ? "rgba(0,0,0,0.04)"
                    : "inherit",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon sx={{ minWidth: 40 }}>{source.icon}</ListItemIcon>
                <ListItemText
                  primary={source.title}
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontWeight: "fontWeightMedium",
                    fontFamily:
                      'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                />
              </Box>
              {selectedSource === source.key && (
                <CheckIcon
                  sx={{
                    // width: "0.85em",
                    // height: "0.85em",
                    fontSize: "small",
                    color: "primary.main",
                    ml: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

ExpandingTextInput.propTypes = {
  userInput: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  setQaType: PropTypes.func.isRequired,
};

export default ExpandingTextInput;
