// Api/instances/axiosInstance.js

import axios from "axios";

export function createAxiosInstance(
  baseURL,
  accessToken,
  contentType,
  responseType = "json",
) {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": contentType || "application/json",
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
    responseType, // Add responseType here, defaulting to 'json'
  });

  instance.interceptors.request.use(
    (config) => {
      // Add the Authorization header on each request
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error),
  );

  return instance;
}
