import React, { lazy, Suspense, useCallback, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
  addFileUploadSource,
  addModuleSource,
  addNewUrlSource,
  removeModuleSource,
} from "@/api/moduleApi";
import Loader from "@/components/shared/Loader";
import useDialogState from "@/hooks/LearningMaterials/useDialogState";
import {
  useLearningMaterials,
  useViewSources,
} from "@/hooks/LearningMaterials/useLearningMaterials";
import {
  SourceTableProps,
  getModuleSourceRowActions,
  getRawColumns,
  getModuleSourceButtonOptions,
} from "@/props-constants/source";
import {
  appendSourcesData,
  setSourceLoading,
} from "@/store/slices/sourceSlice";
import { setToast } from "@/store/slices/toastSlice";
import { handleApiError } from "@/utils/common-utils";

// Lazy-loaded dialogs for optimization
const AddFilesDialog = lazy(
  () => import("@/components/shared/Modals/AddFilesDialog"),
);
const AddURLsDialog = lazy(
  () => import("@/components/shared/Modals/AddURLsDialog"),
);
const AvailableSourcesModal = lazy(
  () => import("@/components/shared/Modals/AvailableSourcesDialog"),
);
const ConfirmationDialog = lazy(
  () => import("@/components/shared/Modals/ConfirmModal"),
);

// Utility function for API calls and state update
const useSourceActions = (moduleId, setModuleDetails) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const updateModuleState = (response, availableSources) => {
    if (availableSources) {
      setModuleDetails(response.data);
    } else {
      setModuleDetails(response.data.module);
      dispatch(appendSourcesData(response.data.sources));
    }
  };

  const handleError = (error, defaultMessage) => {
    handleApiError(error, dispatch, defaultMessage);
  };

  /**
   * Utility function for modifying module sources
   * @param {Function} apiFn - API function to call (e.g., addModuleSource, removeModuleSource)
   * @param {Object} payload - Data to be sent to the API
   * @param {string} successMessage - Success message to show on completion
   */
  const modifySource = useCallback(
    async (apiFn, payload, successMessage, availableSources = false) => {
      dispatch(setSourceLoading(true));
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await apiFn(accessToken, moduleId, payload);
        updateModuleState(response, availableSources);
        dispatch(
          setToast({
            message: successMessage,
            toasterColor: "success",
          }),
        );
      } catch (error) {
        handleError(error, "Failed to modify module source.");
      } finally {
        dispatch(setSourceLoading(false));
      }
    },
    [dispatch, getAccessTokenSilently, moduleId],
  );

  return { modifySource };
};

const LearningMaterials = ({
  moduleSources,
  moduleId,
  setModuleDetails,
  tutorStatus,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { filteredSources, availableSources, loading } =
    useLearningMaterials(moduleSources);
  const {
    fileDialogOpen,
    urlDialogOpen,
    modalOpen,
    deleteDialogOpen,
    deletingSource,
    openFileDialog,
    closeFileDialog,
    openUrlDialog,
    closeUrlDialog,
    openModal,
    closeModal,
    openDeleteDialog,
    closeDeleteDialog,
  } = useDialogState();

  const { modifySource } = useSourceActions(moduleId, setModuleDetails);

  const handleSourceClick = useViewSources();

  const columns = useMemo(() => getRawColumns(isMobile), [isMobile]);

  const handleButtonOptionClick = useCallback(
    (option) => {
      if (option.key === "add") {
        openModal();
      } else if (option.key === "uploadFile") {
        openFileDialog();
      } else if (option.key === "addUrl") {
        openUrlDialog();
      }
    },
    [openModal, openFileDialog, openUrlDialog],
  );

  const handleRowOptionClick = useCallback(
    (row, option) => {
      if (option === "delete") {
        openDeleteDialog(row);
      } else if (option === "view") {
        if (row.original.source_type === "url") {
          window.open(row.original.url, "_blank", "noopener,noreferrer");
        } else {
          handleSourceClick(row.id, moduleId);
        }
      }
    },
    [openDeleteDialog, moduleId],
  );

  const handleDeleteConfirm = useCallback(async () => {
    await modifySource(
      removeModuleSource,
      { data_source_id: deletingSource.id },
      "Source removed successfully.",
      true,
    );
    closeDeleteDialog();
  }, [modifySource, deletingSource, closeDeleteDialog]);

  const handleAddSelectedSource = useCallback(
    async (selectedSources) => {
      await modifySource(
        addModuleSource,
        { data_source_ids: selectedSources },
        "Sources added successfully.",
        true,
      );
      closeModal();
    },
    [modifySource, closeModal],
  );

  const handleAddUploadFile = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      await modifySource(
        addFileUploadSource,
        formData,
        "Files uploaded successfully.",
      );
      closeFileDialog();
    },
    [modifySource, closeFileDialog],
  );

  const handleAddNewUrl = useCallback(
    async (acceptedUrls) => {
      await modifySource(
        addNewUrlSource,
        { urls: acceptedUrls },
        "URLs added successfully.",
      );
      closeUrlDialog();
    },
    [modifySource, closeUrlDialog],
  );

  const table = useMaterialReactTable({
    columns,
    data: filteredSources,
    ...SourceTableProps(
      filteredSources,
      isMobile,
      loading,
      getModuleSourceButtonOptions(),
      handleButtonOptionClick,
      getModuleSourceRowActions(handleRowOptionClick),
      tutorStatus === "processing",
    ),
  });

  return (
    <Box mt={2}>
      <MaterialReactTable table={table} />
      <Suspense fallback={<Loader message="Loading Add Sources Dialog..." />}>
        <AvailableSourcesModal
          columns={columns}
          sources={availableSources}
          isMobile={isMobile}
          loading={loading}
          open={modalOpen}
          handleClose={closeModal}
          handleSelectSources={handleAddSelectedSource}
        />
      </Suspense>

      <Suspense fallback={<Loader message="Loading Add Files Dialog..." />}>
        <AddFilesDialog
          open={fileDialogOpen}
          handleClose={closeFileDialog}
          handleUploadFile={handleAddUploadFile}
          uploading={loading}
        />
      </Suspense>
      <Suspense fallback={<Loader message="Loading Add urls Dialog..." />}>
        <AddURLsDialog
          open={urlDialogOpen}
          handleClose={closeUrlDialog}
          handleAddUrl={handleAddNewUrl}
          loading={loading}
        />
      </Suspense>

      <Suspense fallback={<Loader message="Loading condirmation Dialog..." />}>
        <ConfirmationDialog
          open={deleteDialogOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={closeDeleteDialog}
          title="Confirm Delete"
          description="Are you sure you want to remove this Source? "
          secondaryText="This action cannot be undone. This will remove the source from the tutor also."
          loading={loading}
        />
      </Suspense>
    </Box>
  );
};

// Prop Types
LearningMaterials.propTypes = {
  moduleSources: PropTypes.array.isRequired,
  moduleId: PropTypes.string.isRequired,
  setModuleDetails: PropTypes.func.isRequired,
  tutorStatus: PropTypes.string.isRequired,
};

export default LearningMaterials;
