import { useCallback, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";

import { getSignedUrl as moduleSignedUrl } from "@/api/moduleApi";
import { getSignedUrl } from "@/api/sourceApi";
import { setToast } from "@/store/slices/toastSlice";

const useLearningMaterials = (moduleSources) => {
  const sources = useSelector((state) => state.sources.sources);
  const loading = useSelector((state) => state.sources.loading);

  const [filteredSources, setFilteredSources] = useState([]);
  const [availableSources, setAvailableSources] = useState([]);

  useEffect(() => {
    if (moduleSources) {
      setFilteredSources(moduleSources);

      const moduleSourceIds = moduleSources.map((ms) => ms._id);
      const newAvailableSources = sources.filter(
        (source) => !moduleSourceIds.includes(source._id),
      );
      setAvailableSources(newAvailableSources);
    }
  }, [sources, moduleSources]);

  return {
    filteredSources,
    availableSources,
    loading,
  };
};

const useViewSources = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  return useCallback(
    async (_id, module_id = "") => {
      try {
        const accessToken = await getAccessTokenSilently();
        let response = null;
        if (module_id) {
          response = await moduleSignedUrl(accessToken, _id, module_id);
        } else {
          response = await getSignedUrl(accessToken, _id);
        }
        // const response = await getSignedUrl(accessToken, _id);

        const signedUrl = response.data.signedUrl;

        // Open the signed URL in a new tab
        window.open(signedUrl, "_blank", "noopener,noreferrer");
      } catch (error) {
        dispatch(
          setToast({
            message: "Failed to download file",
            toasterColor: "error",
          }),
        );
      }
    },
    [getAccessTokenSilently, dispatch],
  );
};

export { useLearningMaterials, useViewSources };
