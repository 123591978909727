import React from "react";

import { DialogActions, Button } from "@mui/material";

const ShareDialogActions = ({ handleShare }) => {
  return (
    <DialogActions p={8}>
      <Button
        onClick={handleShare}
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        sx={{
          textTransform: "none",
          borderRadius: "8px",
          fontSize: "0.75rem",
          // backgroundColor: "#e0f2ff",
          // color: "#000",
          // "&:hover": {
          //     backgroundColor: "#b3e5fc",
          // },
        }}
        aria-label="Share the document"
      >
        Done
      </Button>
    </DialogActions>
  );
};

export default ShareDialogActions;
