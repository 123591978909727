import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { History, AllInbox, Person } from "@mui/icons-material";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HttpsIcon from "@mui/icons-material/Https";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ShareIcon from "@mui/icons-material/Share";
import SubjectIcon from "@mui/icons-material/Subject";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import FilterHeader from "@/components/shared/FilterHeader";
import GeneralCard from "@/components/shared/GeneralCard";
import { getLabel, getStatusColor } from "@/props-constants/TutorProps";
import { getRandomLightColor } from "@/utils/common-utils";
import { formatDate } from "@/utils/helperUtils";

const ListModuleBody = ({
  items,
  handleViewClick,
  handleChatClick,
  handleDeleteModuleClick,
  handleNewNoteClick,
  handleShareClick,
  handleRenameClick,
}) => {
  const theme = useTheme();

  const [filter, setFilter] = useState("all");
  const [filteredItems, setFilteredItems] = useState([]);

  const { user } = useAuth0();

  const filters = [
    { label: "All", icon: <AllInbox />, filterType: "all" },
    {
      label: "Recently viewed",
      icon: <History />,
      filterType: "recentlyViewed",
    },
    { label: "Created by you", icon: <Person />, filterType: "createdByYou" },
    { label: "Shared with you", icon: <ShareIcon />, filterType: "shared" },
  ];

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  useEffect(() => {
    const newItems = items.filter((item) => {
      if (filter === "recentlyViewed") {
        return item.updated_at;
      } else if (filter === "createdByYou") {
        return item.user_id === user.sub;
      } else if (filter === "shared") {
        return item.user_id !== user.sub;
      }
      return true;
    });
    if (filter === "recentlyViewed") {
      newItems.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    }
    setFilteredItems(newItems);
  }, [filter, items]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={4}>
        {filters.map(({ label, icon, filterType }) => (
          <FilterHeader
            key={filterType}
            icon={icon}
            label={label}
            filterType={filterType}
            currentFilter={filter}
            onClick={handleFilterChange}
          />
        ))}
      </Box>
      <Grid container spacing={4}>
        {filteredItems.map((module) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={module._id}>
            <GeneralCard
              title={module.name}
              status={getLabel(module.tutor_status)}
              mediaBackgroundColor={getRandomLightColor(theme)}
              createdAt={formatDate(module.created_at)}
              user={user}
              ownerId={module.user_id}
              createdByDetail={module.created_by_details}
              onCardClick={() => handleViewClick(module._id)}
              additionalChips={[
                {
                  label: getLabel(module.tutor_status),
                  tooltip: "Tutor Status",
                  backgroundColor: getStatusColor(module.tutor_status, theme),
                  textColor: theme.palette.text.primary,
                },
                {
                  label: module.shared_with.length > 0 ? "Shared" : "Private",
                  tooltip: "Sharing Details",
                  icon:
                    module.shared_with.length > 0 ? (
                      <CoPresentIcon fontSize="14px" />
                    ) : (
                      <HttpsIcon fontSize="14px" />
                    ),
                  textColor: theme.palette.text.primary,
                },
              ]}
              menuItems={[
                {
                  label: "Rename",
                  icon: <EditIcon fontSize="14" />,
                  onClick: () => handleRenameClick(module),
                },
                {
                  label: "Share",
                  icon: <ShareIcon fontSize="14" />,
                  onClick: () => handleShareClick(module),
                },
                {
                  label: "Ask Tutor",
                  icon: <QuestionAnswerOutlinedIcon fontSize="14" />,
                  onClick: () => handleChatClick(module._id),
                  divider: true,
                },
                {
                  label: "Create Note",
                  icon: <SubjectIcon fontSize="14" />,
                  onClick: () => handleNewNoteClick(module),
                },
                {
                  label: "Delete",
                  icon: (
                    <DeleteIcon fontSize="small" style={{ color: "red" }} />
                  ),
                  onClick: () => handleDeleteModuleClick(module._id),
                  color: "red",
                  divider: true,
                  // Only show the "Delete" option if the module was created by the current user
                  hidden: module.user_id !== user.sub,
                },
              ]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ListModuleBody;
