const config = {
  envName: "staging",
  lmsApi: "https://staging-lms-api.lrnd.ai",
  lrndApi: "https://staging-lrnd-backend-api.lrnd.ai",
  // lrndApi: "http://127.0.0.1:5000",
  domain: "dev-md7jm3mx07jp4f18.uk.auth0.com",
  clientId: "Otp6EqPHY3mOkI9kB0RKVYbsuF9YgwCh",
  audience: "https://dev-md7jm3mx07jp4f18.uk.auth0.com/api/v2/",
  // redirectUri: "https://staging-app.lrnd.ai/",
  redirectUri: "http://localhost:3000/",
  webSocketUrl: "wss://staging-collab-api.lrnd.ai/v1/collab",
  // ragApi: "https://staging-rag-api.lrnd.ai",
  ragApi: "http://127.0.0.1:5002",
};

export default config;
