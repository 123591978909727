import { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";

import Loader from "@/components/shared/Loader";
import getIcon from "@/utils/get-icon";

const AddNoteDialog = ({
  open,
  handleAddNote,
  handleClose,
  loading,
  module,
}) => {
  const [title, setTitle] = useState("");
  const [nameError, setNameError] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCreate = () => {
    if (title.trim()) {
      handleAddNote({ name: title.trim() });
      // handleClose();
    } else {
      setNameError(true);
    }
  };

  const handleNameChange = (e) => {
    setTitle(e.target.value);
    if (e.target.value.trim()) {
      setNameError(false);
    }
  };

  if (loading) return <Loader message={"Creating Note"} />;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{ padding: "1rem" }}
    >
      <DialogTitle>Add Note {module && "- " + module.name}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ mt: 1 }}
        >
          <TextField
            required
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              mb: 2,
            }}
            value={title}
            onChange={handleNameChange}
            error={nameError}
            helperText={
              nameError ? "Note title is required" : "The name of this note."
            }
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "1rem", gap: 2 }}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          startIcon={getIcon("add")}
          color="primary"
          variant="contained"
          sx={{
            fontWeight: 700,
            width: isMobile ? "100%" : "auto",
          }}
          onClick={() => handleCreate()}
        >
          Create Note
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAddNote: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  module: PropTypes.object,
};

export default AddNoteDialog;
