import instance from "./instances/lrndBackend";

export function getModules(accessToken) {
  return instance(accessToken).get("/v1/module");
}

export function getModuleDetails(accessToken, moduleId) {
  return instance(accessToken).get(`/v1/module/${moduleId}`);
}

export const addModule = (accessToken, payload) => {
  return instance(accessToken).post("/v1/module", payload);
};

export const deleteModule = (accessToken, moduleId) => {
  return instance(accessToken).delete(`/v1/module/${moduleId}`);
};

export const updateModule = (accessToken, moduleId, payload) => {
  return instance(accessToken).put(`/v1/module/${moduleId}`, payload);
};

export const addModuleSource = (accessToken, moduleId, payload) => {
  return instance(accessToken).put(
    `/v1/module/${moduleId}/source/add`,
    payload,
  );
};

export const removeModuleSource = (accessToken, moduleId, payload) => {
  return instance(accessToken).put(
    `/v1/module/${moduleId}/source/remove`,
    payload,
  );
};

export const addFileUploadSource = (accessToken, moduleId, payload) => {
  return instance(accessToken, "multipart/form-data").post(
    `/v1/module/${moduleId}/files`,
    payload,
  );
};

export const addNewUrlSource = (accessToken, moduleId, payload) => {
  return instance(accessToken).post(`/v1/module/${moduleId}/urls`, payload);
};

export const buildTutor = (accessToken, moduleId) => {
  return instance(accessToken).get(`/v1/module/${moduleId}/tutor/build`);
};

export const reBuildTutor = (accessToken, moduleId) => {
  return instance(accessToken).get(`/v1/module/${moduleId}/tutor/re-build`);
};

export const shareModule = (accessToken, moduleId, payload) => {
  return instance(accessToken).post(`/v1/module/${moduleId}/share`, payload);
};

export const unShareModule = (accessToken, moduleId, payload) => {
  return instance(accessToken).post(`/v1/module/${moduleId}/remove`, payload);
};

export const getSignedUrl = (accessToken, _id, module_id) => {
  return instance(accessToken).get(
    `/v1/module/${module_id}/get-signed-url/${_id}`,
  );
};

export const get_feedback_url = (accessToken) => {
  return instance(accessToken).get("/v1/user/token");
};
