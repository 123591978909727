import { useState } from "react";

const useDialogState = () => {
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [urlDialogOpen, setUrlDialogOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingSource, setDeletingSource] = useState(null);

  const openFileDialog = () => setFileDialogOpen(true);
  const closeFileDialog = () => setFileDialogOpen(false);

  const openUrlDialog = () => setUrlDialogOpen(true);
  const closeUrlDialog = () => setUrlDialogOpen(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const openDeleteDialog = (source) => {
    setDeletingSource(source);
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => setDeleteDialogOpen(false);

  return {
    fileDialogOpen,
    urlDialogOpen,
    modalOpen,
    deleteDialogOpen,
    deletingSource,
    openFileDialog,
    closeFileDialog,
    openUrlDialog,
    closeUrlDialog,
    openModal,
    closeModal,
    openDeleteDialog,
    closeDeleteDialog,
  };
};

export default useDialogState;
