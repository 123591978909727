import { useCallback, useEffect, useState, lazy, Suspense } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { Box } from "@mui/material";
import { fromUint8Array, toUint8Array } from "js-base64";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Y from "yjs";

import {
  getNoteDetails,
  restoreNotesVersion,
  updateNote,
  updateNoteTitle,
} from "@/api/notesApi.js";
import AskTutorButton from "@/components/modules/Notes/EditorV2/partials/AskTutorButton";
import BlockNote from "@/components/modules/Notes/EditorV2/partials/BlockNote.jsx";
import EditorToolbar from "@/components/modules/Notes/EditorV2/partials/EditorToolbar.jsx";
import Loader from "@/components/shared/Loader/index.jsx";
import constants from "@/constants";
import { back } from "@/store/actions/navigationActions";
import { setToast } from "@/store/slices/toastSlice.js";

const VersioningModal = lazy(
  () =>
    import(
      "@/components/modules/Notes/EditorV2/partials/VersionHistoryModal.jsx"
    ),
);

const Editor = () => {
  const { moduleId, noteId } = useParams();

  const [noteTitle, setNoteTitle] = useState(""); // State for note title
  const [loading, setLoading] = useState(false);
  const [versionHistoryOpen, setVersionHistoryOpen] = useState(false);
  const [showRefreshAlert, setShowRefreshAlert] = useState(false);

  // State for Y.Doc, provider, and connection status
  const [yDoc, setYDoc] = useState(new Y.Doc());
  const [provider, setProvider] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false); // To disable save if revert broadcast is received
  const [isConnected, setIsConnected] = useState(true); // To track connection status
  const [reverting, setReverting] = useState(false);
  const [titleError, setTitleError] = useState(null);

  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();

  // Initialize HocuspocusProvider
  useEffect(() => {
    if (!isConnected) return; // Do not reconnect if the user is disconnected

    const newProvider = new HocuspocusProvider({
      url: constants.webSocketUrl,
      name: noteId,
      document: yDoc,
      preserveConnection: false,
      onOpen() {
        console.log("WebSocket connection opened.");
      },
      onConnect() {
        console.log("Connected to the server.");
      },
      onDisconnect() {
        console.log("Disconnected to the server.");
      },
      onStateless: ({ payload }) => {
        const data = JSON.parse(payload);

        if (data.action === "version.revert") {
          if (data && data.data) {
            const isCurrentUser = data.user.id === user.sub;

            if (isCurrentUser) {
              resetYDocWithUpdate(toUint8Array(data.data));
            } else {
              setIsSaveDisabled(true); // Disable saving for this user
              handleDisconnect();
            }
          }
        }
      },
    });

    setProvider(newProvider);

    return () => {
      newProvider.destroy();
    };
  }, [noteId, yDoc, user, isConnected]);

  const fetchNoteDetails = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getNoteDetails(accessToken, noteId, moduleId);
      setNoteTitle(response.data.title); // Set note title
      const savedState = response.data.y_doc_state;

      if (savedState) {
        const bytes = toUint8Array(savedState);
        Y.applyUpdate(yDoc, bytes);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setToast({
          message:
            error.response?.data?.error?.message || "Failed to load the note",
          toasterColor: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNoteDetails();
  }, []);

  const saveNote = useCallback(
    debounce(async (content) => {
      if (reverting) return;
      if (isSaveDisabled) {
        dispatch(
          setToast({
            message:
              "Save is disabled because another user reverted the document.",
            toasterColor: "error",
          }),
        );
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently();
        const base64State = fromUint8Array(Y.encodeStateAsUpdate(yDoc));
        const payload = {
          content: content,
          y_doc_state: base64State,
        };
        await updateNote(accessToken, noteId, payload, moduleId);
      } catch (error) {
        console.error("Error saving note:", error);
      }
    }, 3000),
    [noteId, yDoc, isSaveDisabled],
  );

  const handleDocumentChange = (newDocument) => {
    saveNote(newDocument);
  };

  const handleBackClick = () => {
    dispatch(back());
  };

  // eslint-disable-next-line no-unused-vars
  const handleRevert = async (currentVersionId, selectedVersion) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await restoreNotesVersion(
        accessToken,
        noteId,
        currentVersionId,
      );
      const savedState = response.data.y_doc_state;

      if (savedState) {
        const bytes = toUint8Array(savedState);

        setIsSaveDisabled(true);
        setReverting(true);
        // Broadcast revert with user info
        provider.sendStateless(
          JSON.stringify({
            action: "version.revert",
            data: fromUint8Array(bytes), // Sending the reverted state
            user: { id: user.sub, name: user.name }, // Add user info
          }),
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = () => {
    // Disconnect the provider and Y.Doc
    if (provider) {
      provider.disconnect();
      setProvider(null);
    }
    if (yDoc) {
      yDoc.destroy();
    }
    setIsConnected(false);
    setShowRefreshAlert(true);
  };

  // Function to reset Y.Doc and apply new update
  const resetYDocWithUpdate = (updateBytes) => {
    setLoading(true);
    yDoc.destroy(); // Destroy the current Y.Doc

    const newYDoc = new Y.Doc(); // Create a new Y.Doc
    Y.applyUpdate(newYDoc, updateBytes); // Apply the new update to the new Y.Doc

    setYDoc(newYDoc);
    setLoading(false);
    setIsSaveDisabled(false);
    setReverting(false);
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleNoteTitleUpdate = async (tempTitle) => {
    try {
      const payload = {
        title: tempTitle,
      };
      const accessToken = await getAccessTokenSilently();
      const response = await updateNoteTitle(
        accessToken,
        noteId,
        payload,
        moduleId,
      );
      setNoteTitle(response.data.title);
      setTitleError(null);
    } catch (error) {
      setTitleError("Failed to update the title. Please try again.");
      // setTempTitle(noteTitle);
    }
  };

  if (loading) {
    return <Loader message={"Loading note content"} />;
  }

  return (
    <Box height={"95vh"} display="flex" flexDirection="column">
      <EditorToolbar
        noteTitle={noteTitle}
        setVersionHistoryOpen={setVersionHistoryOpen}
        onBackClick={handleBackClick}
        showRefreshAlert={showRefreshAlert}
        handleReload={handleReload}
        provider={provider}
        titleError={titleError}
        setTitleError={setTitleError}
        handleUpdateNoteTitle={handleNoteTitleUpdate}
      />

      <BlockNote
        provider={provider}
        user={user}
        doc={yDoc}
        handleDocumentChange={handleDocumentChange}
      />

      <AskTutorButton moduleId={moduleId} />
      <Suspense fallback={<Loader message="Loading Dialog..." />}>
        <VersioningModal
          isOpen={versionHistoryOpen}
          onClose={() => setVersionHistoryOpen(false)}
          onRevert={handleRevert}
          documentId={noteId}
        />
      </Suspense>
    </Box>
  );
};

export default Editor;
