import React from "react";

import {
  Menu,
  MenuItem,
  Divider,
  Box,
  Typography,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

const GeneralMenu = ({
  anchorEl,
  onClose,
  menuItems = [],
  selectedItem = {},
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      elevation={3}
      PaperProps={{
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.04))",
          mt: 1.5,
          borderRadius: "16px",
          minWidth: "350px",
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {selectedItem && (
        <Box sx={{ margin: "4px 16px 16px 16px" }}>
          <Typography
            component="div"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              minHeight: "1em",
              fontSize: isSmallScreen ? "0.875rem" : "1.25rem",
            }}
          >
            {selectedItem.title}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen ? "0.75rem" : "0.9em",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              {selectedItem.createdBy
                ? `Created at ${selectedItem.createdAt}`
                : "Unknown creator"}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen ? "0.65rem" : "0.9em",
                fontWeight: "bold",
              }}
            >
              by {selectedItem.createdBy}
            </Typography>
          </Box>
        </Box>
      )}
      <Divider />
      {menuItems.map((item, index) =>
        item.hidden ? null : (
          <Box key={index}>
            {item.divider && <Divider sx={{ mb: 2, mt: 2 }} />}
            <MenuItem
              key={index}
              onClick={() => {
                item.onClick();
                onClose();
              }}
              sx={{
                margin: "8px",
                borderRadius: "4px",
                color: item.color || "inherit",
              }}
              disabled={item.disabled}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Typography variant="body1">{item.label}</Typography>
            </MenuItem>
          </Box>
        ),
      )}
    </Menu>
  );
};

GeneralMenu.propTypes = {
  anchorEl: PropTypes.object, // The anchor element for the menu
  onClose: PropTypes.func.isRequired, // Function to handle closing the menu
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired, // Label for the menu item
      icon: PropTypes.element.isRequired, // Icon for the menu item
      onClick: PropTypes.func.isRequired, // Click handler for the menu item
      color: PropTypes.string, // Optional color for the menu item
      disabled: PropTypes.bool, // Whether the menu item is disabled
      divider: PropTypes.bool, // Whether to render a divider before this item
      hidden: PropTypes.bool,
    }),
  ).isRequired,
  selectedItem: PropTypes.shape({
    title: PropTypes.string, // Title of the selected item
    createdBy: PropTypes.string, // Creator of the selected item
    createdAt: PropTypes.string, // Creation date of the selected item
  }),
};

export default GeneralMenu;
