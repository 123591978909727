import React, { useEffect, useState } from "react";

import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { Box, Typography, TextField } from "@mui/material";
import PropTypes from "prop-types";

import Loader from "@/components/shared/Loader";
import { formatDate } from "@/utils/helperUtils";

const HistoryList = ({ chatHistory, historyLoading, onSelectConversation }) => {
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [groupedHistory, setGroupedHistory] = useState(chatHistory);

  const groupChatHistory = (chats) => {
    const now = new Date();
    const todayStart = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    );
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(todayStart.getDate() - 1);
    const oneWeekAgo = new Date(todayStart);
    oneWeekAgo.setDate(todayStart.getDate() - 7);
    const oneMonthAgo = new Date(todayStart);
    oneMonthAgo.setDate(todayStart.getDate() - 30);

    const groups = {
      Today: [],
      Yesterday: [],
      "Past Week": [],
      "1 Month": [],
      Older: [],
    };

    chats.forEach((chat) => {
      const chatDate = new Date(chat.created_at);

      if (chatDate >= todayStart) {
        groups.Today.push(chat);
      } else if (chatDate >= yesterdayStart && chatDate < todayStart) {
        groups.Yesterday.push(chat);
      } else if (chatDate >= oneWeekAgo && chatDate < yesterdayStart) {
        groups["Past Week"].push(chat);
      } else if (chatDate >= oneMonthAgo && chatDate < oneWeekAgo) {
        groups["1 Month"].push(chat);
      } else {
        groups.Older.push(chat);
      }
    });

    return groups;
  };

  // Filter chat history based on the search query
  const filteredChatHistory = chatHistory.filter((chat) =>
    chat.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    if (!historyLoading) {
      setGroupedHistory(groupChatHistory(filteredChatHistory));
    }
  }, [historyLoading]);

  if (historyLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Loader size={24} message={"Loading Conversations"} />
      </Box>
    );
  }

  return (
    <Box sx={{ px: 1, overflowY: "auto" }}>
      {/* Search Bar */}
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search chat history"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          sx={{
            borderRadius: "8px",
          }}
          inputProps={{
            sx: {
              fontSize: "12px",
              padding: "8px",
            },
          }}
        />
      </Box>

      {/* Display "No Results Found" if there are no matching results */}
      {Object.values(groupedHistory).every((group) => group.length === 0) ? (
        <Box textAlign="center" sx={{ mt: 3 }}>
          <Typography
            variant="subtitle2"
            fontWeight="medium"
            sx={{ fontSize: "14px", color: "rgba(55, 53, 47, 0.65)" }}
          >
            No results found
          </Typography>
        </Box>
      ) : (
        Object.keys(groupedHistory).map((group) => {
          if (groupedHistory[group].length > 0) {
            return (
              <Box key={group} sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  sx={{
                    mb: 2,
                    fontSize: "12px",
                    color: "rgba(55, 53, 47, 0.65)",
                  }}
                >
                  {group}
                </Typography>
                {groupedHistory[group].map((chat) => (
                  <Box
                    key={chat.id}
                    onClick={() => {
                      onSelectConversation(chat.id);
                    }}
                    display="flex"
                    justifyContent="flex-start"
                    gap={2}
                    alignItems="center"
                    sx={{
                      color: "inherit",
                      py: 2,
                      px: 2,
                      mb: 1,
                      fontSize: "0.7rem",
                      fontWeight: 600,
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "rgb(227, 226, 224)",
                      },
                    }}
                  >
                    <QuestionAnswerOutlinedIcon
                      sx={{
                        width: "0.75em",
                        height: "0.75em",
                        fill: "rgb(0 0 0 / 52%)",
                      }}
                    />
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="medium"
                        fontFamily={
                          'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"'
                        }
                        color={"rgb(55, 53, 47)"}
                      >
                        {chat.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "rgb(150, 150, 150)" }}
                      >
                        {formatDate(chat.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          }
          return null;
        })
      )}
    </Box>
  );
};

HistoryList.propTypes = {
  chatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  ).isRequired,
  historyLoading: PropTypes.bool,
  onSelectConversation: PropTypes.func,
};

export default HistoryList;
