import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";

import Loader from "@/components/shared/Loader";

const ConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
  title,
  description,
  secondaryText = "",
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="h6" component="h3" fontWeight="medium">
          {description}
        </Typography>

        {secondaryText && (
          <Typography variant="body1" mt={2}>
            {secondaryText}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2, gap: 1 }}>
        <Button onClick={onCancel} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>

      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <Loader />
        </Box>
      )}
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmationDialog;
