import React, { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Tooltip, Typography, TextField } from "@mui/material";
import PropTypes from "prop-types";
import {
  RxChatBubble,
  RxCounterClockwiseClock,
  RxPencil1,
} from "react-icons/rx";

const ConversationHeader = ({
  moduleName,
  conversation,
  handleNewChatClicked,
  handleBack,
  handleTitleUpdate, // New prop for updating the title
  handleDrawerOpen,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(conversation?.title || "");

  useEffect(() => {
    if (conversation?.title) {
      setTitle(conversation.title);
    } else {
      setTitle("");
    }
  }, [conversation?.title]);

  const handleRenameClick = () => {
    setIsEditing(true);
  };

  const handleRenameBlur = () => {
    setIsEditing(false);
    if (conversation?.title !== title) {
      handleTitleUpdate(title, conversation.id); // Call the update function if the title has changed
    }
  };

  const handleRenameChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        justifyContent: "space-between",
        width: "100%",
        background: "#FFF",
        p: 2,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Tooltip title="Back to Module">
          <IconButton
            onClick={handleBack}
            aria-label="Back to Module"
            fontSize="small"
          >
            <ArrowBackIcon sx={{ width: "0.7em", height: "0.7em" }} />
          </IconButton>
        </Tooltip>

        {isEditing ? (
          <TextField
            value={title}
            onChange={handleRenameChange}
            onBlur={handleRenameBlur}
            autoFocus
            variant="outlined"
            size="small"
            sx={{
              width: "200px",
              "& .MuiInputBase-input": {
                fontSize: "0.875rem",
                // fontWeight: 'bold',
              },
            }}
          />
        ) : (
          <Typography
            component="p"
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
          >
            {title || "New Chat"}
          </Typography>
        )}

        {!isEditing && title && (
          <Tooltip title="Rename">
            <IconButton onClick={handleRenameClick}>
              <RxPencil1 fontSize="18px" strokeWidth="0.02em" />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Tooltip title="Module Name">
          <Typography component="p">{moduleName}</Typography>
        </Tooltip>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Tooltip title="New Chat">
          <IconButton onClick={handleNewChatClicked}>
            <RxChatBubble fontSize="18px" strokeWidth="0.02em" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Chat History">
          <IconButton onClick={handleDrawerOpen}>
            <RxCounterClockwiseClock fontSize="18px" strokeWidth="0.02em" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

ConversationHeader.propTypes = {
  moduleName: PropTypes.string.isRequired,
  conversation: PropTypes.shape({
    title: PropTypes.string,
  }),
  handleNewChatClicked: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleTitleUpdate: PropTypes.func.isRequired, // New prop for handling title updates
  handleDrawerOpen: PropTypes.func,
};

export default ConversationHeader;
