import { useAuth0 } from "@auth0/auth0-react";

const useAuth = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return { isLoading };
  }

  if (error) {
    return { error };
  }

  if (!isAuthenticated) {
    loginWithRedirect({});
    return { isLoading: false };
  }

  return { isAuthenticated };
};

export default useAuth;
