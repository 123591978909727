import React from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import { DialogTitle, Typography, Box, IconButton } from "@mui/material";

const ShareDialogTitle = ({ title, handleClose }) => {
  return (
    <DialogTitle>
      <Box display="flex" alignItems="center">
        <ShareIcon sx={{ mr: 2 }} fontSize="medium" />
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          Share {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="small"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};

export default ShareDialogTitle;
