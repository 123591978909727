// AskTutorDialog.jsx
import React, { useState, useEffect, useRef } from "react";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import HistoryList from "@/components/shared/ConversationHistoryList";
import ExpandingTextInput from "@/components/shared/ExpandingTextInput";
import MessageList from "@/components/shared/MessageList";

const AskTutorDialog = ({
  open,
  onClose,
  userInput,
  onInputChange,
  onSend,
  isBotTyping,
  userName,
  conMessages,
  chatHistory,
  historyLoading,
  fetchConversations,
  handleSelectConversation,
  messageLoading,
  moduleId,
  setQaType,
}) => {
  const [showHistory, setShowHistory] = useState(false);
  // const [chatHistory, setChatHistory] = useState([]);
  const dialogRef = useRef(null);

  // Simulate API call to fetch chat history
  useEffect(() => {
    if (showHistory) {
      fetchConversations();
    }
  }, [showHistory]);

  const handleSelectConversationClick = (conversationId) => {
    handleSelectConversation(conversationId);
    setShowHistory(false);
    // Additional logic to load the selected conversation
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      ref={dialogRef}
      disableEnforceFocus={true}
      disableScrollLock={true}
      hideBackdrop={true}
      style={{ position: "initial" }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
      // disableBackdropClick
      PaperProps={{
        component: Paper,
        sx: {
          zIndex: 1200,
          position: "fixed",
          bottom: 24,
          right: 24,
          margin: 0,
          width: 450,
          minHeight: "min(61vh, 365px)",
          maxHeight: "max(-76px + 100vh, 365px)",
          maxWidth: "80%",
          borderRadius: 5,
          overflow: "hidden",
          pointerEvents: "auto", // Ensure dialog allows interaction
        },
      }}
      slotProps={{
        backdrop: { invisible: true },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
      >
        <Box display="flex" alignItems="center">
          {showHistory && (
            <IconButton
              onClick={() => setShowHistory(false)}
              aria-label="Back to chat"
              sx={{ marginRight: 1 }}
            >
              <ArrowBackIcon sx={{ width: "0.8em", height: "0.8em" }} />
            </IconButton>
          )}
          <Typography
            variant="subtitle2"
            fontWeight="medium"
            sx={{
              fontSize: "13px",
              color: "rgb(55, 53, 47)",
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {showHistory ? "Chat History" : "Ask Tutor"}
          </Typography>
        </Box>
        <Box>
          {showHistory ? (
            <Tooltip title={"Start new chat"}>
              <IconButton
                onClick={() => setShowHistory(false)}
                aria-label="New chat"
                size="small"
                sx={{
                  borderRadius: 1,
                  backgroundColor: "rgb(227, 226, 224)",
                  "&:hover": {
                    backgroundColor: "rgb(190 190 189)",
                  },
                }}
              >
                <AddCircleOutlineOutlinedIcon
                  color="action"
                  sx={{ width: "0.65em", height: "0.65em", color: "#8b8b8a" }}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontSize="small"
                  px={1}
                >
                  New Chat
                </Typography>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={"Chat history"}>
              <IconButton
                onClick={() => setShowHistory(true)}
                aria-label="Show history"
              >
                <HistoryIcon sx={{ width: "0.65em", height: "0.65em" }} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={"Close"}>
            <IconButton onClick={onClose} aria-label="Close dialog">
              <CloseIcon sx={{ width: "0.65em", height: "0.65em" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <DialogContent sx={{ overflowY: "auto", p: 1 }}>
        {showHistory ? (
          <HistoryList
            chatHistory={chatHistory}
            historyLoading={historyLoading}
            onSelectConversation={handleSelectConversationClick}
          />
        ) : (
          <MessageList
            messageLoading={messageLoading}
            messages={conMessages}
            isBotTyping={isBotTyping}
            userName={userName}
            dialogRef={dialogRef}
            moduleId={moduleId}
          />
        )}
      </DialogContent>

      {!showHistory && (
        <Box p={1}>
          <ExpandingTextInput
            userInput={userInput}
            onInputChange={onInputChange}
            onSend={onSend}
            setQaType={setQaType}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            textAlign={"center"}
            sx={{ mt: 2 }}
          >
            Lrnd.ai can make mistakes. Please double check responses.
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

AskTutorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userInput: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  conMessages: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.oneOf(["user", "assistant"]).isRequired,
      content: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  ),
  isBotTyping: PropTypes.bool,
  userName: PropTypes.string,
  chatHistory: PropTypes.arrayOf(PropTypes.shape({})),
  historyLoading: PropTypes.bool,
  fetchConversations: PropTypes.func,
  handleSelectConversation: PropTypes.func,
  messageLoading: PropTypes.bool,
  moduleId: PropTypes.string.isRequired,
  setQaType: PropTypes.func.isRequired,
};

export default AskTutorDialog;
