import React from "react";

import { Paper, Typography, Grid, Box, Avatar, TextField } from "@mui/material";
import PropTypes from "prop-types";

const ProfileDetails = ({ details }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={4}>
      <Paper
        elevation={0}
        sx={{ padding: 4, borderRadius: "1rem", border: "1px solid #b4caf7" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2} md={1}>
            <Avatar
              alt={details.name}
              src={details.picture}
              sx={{ width: 40, height: 40 }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={11}>
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="body1"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                Name
              </Typography>
              <TextField
                disabled
                id="outlined-disabled"
                size="small"
                fullWidth
                value={details.name}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{ marginBottom: 1, fontWeight: "bold", marginTop: 4 }}
              >
                Email
              </Typography>
              <TextField
                disabled
                id="outlined-disabled"
                size="small"
                fullWidth
                value={details.email}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={0}
        sx={{ padding: 4, borderRadius: "1rem", border: "1px solid #b4caf7" }}
      >
        <Typography
          variant="body1"
          sx={{ marginBottom: 1, fontWeight: "bold" }}
        >
          University
        </Typography>
        <TextField
          disabled
          id="outlined-disabled"
          size="small"
          fullWidth
          value={details.university_name}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
        />
        <Typography
          variant="body1"
          sx={{ marginBottom: 1, fontWeight: "bold", marginTop: 4 }}
        >
          Discipline
        </Typography>
        <TextField
          disabled
          id="outlined-disabled"
          size="small"
          fullWidth
          value={details.discipline}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
        />
      </Paper>
    </Box>
  );
};

ProfileDetails.propTypes = {
  details: PropTypes.object.isRequired,
};

export default ProfileDetails;
