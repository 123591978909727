import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Fab, Tooltip, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { getModuleConversations } from "@/api/conversation";
import AskTutorDialog from "@/components/shared/Modals/AskTutorDialog";
import useMessageHandler from "@/hooks/Message/useMessageHandler";
import { setToast } from "@/store/slices/toastSlice";

const AskTutorButton = ({ moduleId }) => {
  const [open, setOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuth0();

  const {
    messages,
    input,
    isBotTyping,
    setInput,
    sendMessage,
    loading,
    fetchMessages,
    setConId,
    setQaType,
  } = useMessageHandler(moduleId, null, null, false, "4", true);

  const handleFabClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInput("");
  };

  const handleInputChange = (input) => {
    setInput(input);
  };

  const fetchConversations = async () => {
    setHistoryLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getModuleConversations(accessToken, moduleId);
      setChatHistory(response.data);
    } catch (e) {
      dispatch(
        setToast({
          message:
            e.response?.data?.error?.message || "Failed to load the history",
        }),
      );
    } finally {
      setHistoryLoading(false);
    }
  };

  const handleSelectConversation = async (conversationId) => {
    setConId(conversationId);
    await fetchMessages({ conversationId });
  };

  return (
    <>
      {!open && (
        <Tooltip title="Ask Tutor">
          <Fab
            aria-label="ask-tutor"
            onClick={handleFabClick}
            size="medium"
            sx={{
              backgroundColor: theme.palette.common.white,
              position: "fixed",
              bottom: 24,
              right: 24,
              boxShadow: 2,
              mr: 1,
              zIndex: 1000,
            }}
          >
            <AutoAwesomeOutlinedIcon />
          </Fab>
        </Tooltip>
      )}

      <AskTutorDialog
        open={open}
        onClose={handleClose}
        userInput={input}
        onInputChange={handleInputChange}
        onSend={sendMessage}
        conMessages={messages}
        isBotTyping={isBotTyping}
        userName={user.name}
        chatHistory={chatHistory}
        historyLoading={historyLoading}
        fetchConversations={fetchConversations}
        handleSelectConversation={handleSelectConversation}
        messageLoading={loading}
        moduleId={moduleId}
        setQaType={setQaType}
      />
    </>
  );
};

AskTutorButton.propTypes = {
  moduleId: PropTypes.string.isRequired,
};

export default AskTutorButton;
