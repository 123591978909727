// @ts-nocheck
import React, { Component } from "react";

import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import { HistoryRouter } from "redux-first-history/rr6";

import { Auth0ProviderWithNavigate } from "@/utils/auth0-provider-with-navigate";
import theme from "@/utils/theme";

import RouterProvider from "./Router";

const INTERCOM_APP_ID = "o2kzd5qd";

class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        autoBoot={false}
        hide_default_launcher={false}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <HistoryRouter history={history}>
                <Auth0ProviderWithNavigate>
                  <RouterProvider />
                </Auth0ProviderWithNavigate>
              </HistoryRouter>
            </StyledEngineProvider>
          </ThemeProvider>
        </Provider>
      </IntercomProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
