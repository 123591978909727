// // components/Conversations/index.js
import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AutoAwesome as AutoAwesomeIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  MenuItem,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useDispatch } from "react-redux";

import {
  deleteConversationApi,
  getModuleConversations,
  renameConversation,
} from "@/api/conversation";
import ErrorComponent from "@/components/shared/ErrorComponent";
import ConfirmationDialog from "@/components/shared/Modals/ConfirmModal";
import { goToRoute } from "@/store/actions/navigationActions";
import {
  deleteConversation,
  updateConversationTitle,
} from "@/store/slices/conversationSlice";
import { setToast } from "@/store/slices/toastSlice";
import theme from "@/utils/theme";

const ConversationList = ({ moduleInfo, moduleId }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingConversation, setDeletingConversation] = useState({});
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);

  // Media query to detect screen size
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchConversations = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getModuleConversations(accessToken, moduleId);
      setConversations(response.data);
      setError(false);
    } catch (e) {
      dispatch(
        setToast({
          message:
            e.response?.data?.error?.message || "Failed to load the history",
        }),
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [moduleId]);

  const handleDeleteConversation = async (chatToDelete) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();

      await deleteConversationApi(accessToken, chatToDelete.id);

      setConversations((prevConversations) =>
        prevConversations.filter(
          (conversation) => conversation._id !== deletingConversation.id,
        ),
      );

      dispatch(deleteConversation(chatToDelete.id));
      dispatch(
        setToast({
          message: "Conversation deleted successfully",
          toasterColor: "success",
        }),
      );
    } catch (error) {
      console.error("Failed to delete conversation:", error);
      dispatch(
        setToast({
          message: error.message,
          toasterColor: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);
    handleDeleteConversation(deletingConversation);
    setDeletingConversation(null);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleRenameConversation = async (values, chatId) => {
    const newTitle = values.title;
    try {
      const accessToken = await getAccessTokenSilently();
      const payload = { title: newTitle };

      const response = await renameConversation(payload, accessToken, chatId);

      if (response.data) {
        // Update local state with the new title
        setConversations((prevConversations) =>
          prevConversations.map((conversation) =>
            conversation.id === chatId
              ? { ...conversation, title: newTitle }
              : conversation,
          ),
        );
        // Dispatch action to update the conversation title in the store
        dispatch(updateConversationTitle({ id: chatId, title: newTitle }));
        dispatch(
          setToast({
            message: `Renamed conversation successfully to ${newTitle}`,
            toasterColor: "success",
          }),
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          message: error.message,
          toasterColor: "error",
        }),
      );
    }
  };

  const conversation_columns = [
    {
      accessorKey: "title",
      header: "Title",
      size: 75,
    },
    {
      accessorKey: "messages",
      header: "Messages",
      enableEditing: false,
      Edit: () => null,
      size: 75,
    },
    {
      accessorKey: "created_at",
      header: "Created On",
      enableEditing: false,
      Edit: () => null,
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      size: 100,
    },
  ];

  // Row Actions for desktop/laptop
  const renderRowActions = ({ row, table }) => (
    <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
      <Tooltip title="Continue conversation" placement="top">
        <IconButton
          color="primary"
          onClick={() => {
            dispatch(goToRoute(`/module/${moduleInfo?._id}/c/${row.id}`));
          }}
        >
          <AutoAwesomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Rename" placement="top">
        <IconButton color="secondary" onClick={() => table.setEditingRow(row)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete this conversation" placement="top">
        <IconButton
          color="error"
          onClick={() => {
            setDeletingConversation(row);
            setDeleteDialogOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  // Row Actions as menu items for mobile/tablet
  const renderRowActionsMenuItem = ({ row, table }) => (
    <>
      <MenuItem
        onClick={() =>
          dispatch(goToRoute(`/module/${moduleInfo?._id}/c/${row.id}`))
        }
      >
        <AutoAwesomeIcon sx={{ mr: 1 }} />
        Continue Conversation
      </MenuItem>
      <MenuItem onClick={() => table.setEditingRow(row)}>
        <EditIcon sx={{ mr: 1 }} />
        Rename
      </MenuItem>
      <Divider sx={{ my: 1 }} />
      <MenuItem
        onClick={() => {
          setDeletingConversation(row);
          setDeleteDialogOpen(true);
        }}
      >
        <DeleteIcon sx={{ mr: 1 }} color="error" />
        Delete
      </MenuItem>
    </>
  );

  const table = useMaterialReactTable({
    columns: conversation_columns,
    data: conversations,
    layoutMode: "grid",
    enableRowSelection: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: "bottom",
    muiTablePaperProps: { elevation: 0 },
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: true,
      density: "spacious",
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 15, 25],
      variant: "outlined",
      elevation: 0,
    },
    muiTopToolbarProps: {
      marginBottom: "2rem",
      marginTop: "2rem",
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        display: "table",
        minWidth: isMobile ? "100%" : "auto",
      },
    },
    state: { showSkeletons: loading },
    paginationDisplayMode: "pages",
    getRowId: (originalRow) => originalRow.id,
    enableRowActions: true,
    positionActionsColumn: "last",
    // Conditionally render row actions based on screen size
    renderRowActions: isMobile ? null : renderRowActions,
    renderRowActionMenuItems: isMobile ? renderRowActionsMenuItem : [],
    onEditingRowSave: ({ table, values, row }) => {
      console.log(row);
      handleRenameConversation(values, row.id);
      table.setEditingRow(null);
    },
  });

  if (error)
    return (
      <ErrorComponent
        message={"Error loading the history"}
        onRetry={fetchConversations}
      />
    );

  return (
    <Box>
      <MaterialReactTable table={table} />
      <ConfirmationDialog
        open={deleteDialogOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Confirm Delete"
        description="Are you sure you want to delete this Conversation?"
      />
    </Box>
  );
};

export default ConversationList;
