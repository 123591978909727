import React from "react";

import { List, ListItemButton, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ProfileTabs = ({ selectedTab, handleTabChange, isMobile }) => {
  return (
    <List
      component="nav"
      aria-label="profile tabs"
      sx={{ display: "flex", flexDirection: isMobile ? "row" : "column" }}
    >
      <ListItemButton
        selected={selectedTab === 0}
        onClick={() => handleTabChange(0)}
        sx={{
          borderRadius: "0.5rem",
          mb: 2,
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
        }}
      >
        <Typography variant="body1" fontWeight={selectedTab === 0 ? 700 : 500}>
          Profile
        </Typography>
      </ListItemButton>
      {/*<ListItemButton*/}
      {/*  selected={selectedTab === 1}*/}
      {/*  onClick={() => handleTabChange(1)}*/}
      {/*  sx={{*/}
      {/*    borderRadius: "0.5rem",*/}
      {/*    mb: 2,*/}
      {/*    paddingTop: "0.75rem",*/}
      {/*    paddingBottom: "0.75rem",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography variant="body1" fontWeight={selectedTab === 1 ? 700 : 500}>*/}
      {/*    Billing*/}
      {/*  </Typography>*/}
      {/*</ListItemButton>*/}
    </List>
  );
};

ProfileTabs.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default ProfileTabs;
