import defaultConfig from "./default";
import prod from "./production.js";
import stag from "./staging";

let envConfig = null;
const env = process.env.APP_ENV;
console.log(env);
switch (env) {
  case "prod":
  case "production":
    envConfig = { ...prod };
    break;
  case "stag":
  case "staging":
    envConfig = { ...stag };
    break;
  default:
    envConfig = { ...defaultConfig };
}

export default envConfig;
