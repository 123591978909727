import React from "react";

import { Button } from "@mui/material";

const FilterHeader = ({ icon, label, filterType, currentFilter, onClick }) => {
  const isActive = currentFilter === filterType;

  return (
    <Button
      startIcon={React.cloneElement(icon, {
        color: isActive ? "blue" : "inherit",
        sx: { width: "0.7em", height: "0.7em" },
      })}
      sx={{
        textTransform: "none",
        color: isActive ? "blue" : "inherit",
        backgroundColor: isActive ? "#F0F0FF" : "inherit",
        mr: 2,
        fontSize: "0.75rem",
        fontWeight: "bold",
        padding: "4px 12px",
      }}
      onClick={() => onClick(filterType)}
    >
      {label}
    </Button>
  );
};

export default FilterHeader;
