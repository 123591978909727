const ROUTE_PATHS = {
  LOGIN: "/login",
  HOME: "/",
  MODULE_DETAILS: "/module/:moduleId/d",
  LIBRARY: "/library",
  PROFILE: "/profile",
  SUBSCRIPTION_SUCCESS: "/checkout/:sessionId",
  CONVERSATION: "/module/:moduleId/c/",
  CONVERSATION_VIEW: "/module/:moduleId/c/:conversationId",
  NOTES_EDITOR: "/module/:moduleId/n/:noteId",
};

export default ROUTE_PATHS;
