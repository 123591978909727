import { useState } from "react";

import { Box } from "@mui/material";
import PropTypes from "prop-types";

import ListNoteBody from "@/components/modules/Notes/ListNotes/partials/body.jsx";
import ListNotesHeader from "@/components/modules/Notes/ListNotes/partials/header.jsx";

const ListNotes = ({
  items,
  user,
  handleAddNote,
  handleDeleteClick,
  handleViewClick,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (newSearchText) => {
    setSearchText(newSearchText);
  };

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        height: "95vh",
        flexDirection: "column",
      }}
      component="main"
    >
      <Box
        sx={{
          padding: 1,
          flexBasis: "10%",
          borderRadius: 2,
        }}
      >
        <ListNotesHeader
          onSearchChange={handleSearchChange}
          searchValue={searchText}
          handleAddClick={handleAddNote}
        />
      </Box>
      <Box
        sx={{
          padding: 2,
          overflowY: "auto",
          flexBasis: "auto",
          flexGrow: 1,
          borderRadius: 2,
          paddingBottom: 2,
        }}
      >
        <ListNoteBody
          items={filteredItems}
          user={user}
          handleViewClick={handleViewClick}
          handleDeleteClick={handleDeleteClick}
        />
      </Box>
    </Box>
  );
};

ListNotes.propTypes = {
  items: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  handleAddNote: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleViewClick: PropTypes.func.isRequired,
};

export default ListNotes;
