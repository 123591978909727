import React from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

const ErrorComponent = ({ message, onRetry }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      p={2}
    >
      <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
      <Typography variant="h6" color="textSecondary">
        {message || "An error occurred while loading the data."}
      </Typography>
      {onRetry && (
        <Button
          variant="contained"
          startIcon={<ReplayIcon />}
          color="primary"
          onClick={onRetry}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      )}
    </Box>
  );
};

ErrorComponent.propTypes = {
  message: PropTypes.string,
  onRetry: PropTypes.func,
};

export default ErrorComponent;
