import React, { useState, useRef } from "react";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";

const DropdownButton = ({
  buttonLabel,
  buttonIcon,
  options,
  onOptionClick,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (event, option) => {
    onOptionClick(option);
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        fullWidth={isMobile}
        disabled={disabled}
        sx={{
          boxShadow: "none",
        }}
      >
        <Button
          aria-controls={open ? "dropdown-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label={buttonLabel}
          aria-haspopup="menu"
          startIcon={buttonIcon}
          sx={{
            width: "100%",
            fontSize: "0.75rem",
          }}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleToggle}
        >
          {buttonLabel}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="dropdown-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      sx={{
                        padding: "0.95rem 1rem",
                        margin: "8px",
                        borderRadius: "8px",
                        fontWeight: "600",
                      }}
                      key={index}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {option.icon}
                      <span style={{ marginLeft: "1rem" }}>{option.label}</span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

DropdownButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonIcon: PropTypes.element.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }),
  ).isRequired,
  onOptionClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DropdownButton;
