import { useAuth0 } from "@auth0/auth0-react";
import { reverse } from "named-urls";
import { push, replace, goBack } from "redux-first-history";

const goToRoute = function (
  path,
  params = {},
  query = null,
  replaceUrl = false,
) {
  return (dispatch) => {
    let routePath = reverse(path, params);
    if (query) {
      const search = new URLSearchParams(query);
      routePath = `${routePath}?${search.toString()}`;
    }
    if (replaceUrl) {
      dispatch(replace(routePath));
    }
    dispatch(push(routePath));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
};

const Logout = function () {
  const { logout } = useAuth0();
  logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
};

const back = function () {
  return (dispatch) => dispatch(goBack());
};

export { goToRoute, Logout, back };
