import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription: { subscription_type: "free" },
  loading: false, //TODO: Change it to true
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateSubscriptionData: (state, action) => {
      state.subscription = action.payload;
      state.loading = false;
    },
  },
});

export const { updateSubscriptionData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
