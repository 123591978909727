const getStatusColor = (status, theme) => {
  switch (status) {
    case "processing":
      return theme.palette.warning.light;
    case "ready":
      return theme.palette.success.light;
    case "pending":
      return theme.palette.info.light;
    case "error":
      return theme.palette.error.light;
    case "not_started":
      return theme.palette.grey["500"];
    default:
      return theme.palette.grey;
  }
};

const getLabel = (status) => {
  // Remove all underscores and split the string into words
  if (status) {
    const words = status?.replace(/_/g, " ").split(" ");

    // Capitalize the first character of each word
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
};

export { getLabel, getStatusColor };
