import { getSourceList } from "@/api/sourceApi";
import { updateSourcesData } from "@/store/slices/sourceSlice";
import { setToast } from "@/store/slices/toastSlice";

import { goToRoute } from "./navigationActions";

export const SourceDataGetAndStore = (accessToken) => {
  return (dispatch) => {
    accessToken
      ? getSourceList(accessToken)
          .then((res) => {
            if (res.data) {
              dispatch(updateSourcesData(res.data));
            }
          })
          .catch((e) => {
            console.log(e);
            dispatch(
              setToast({
                message: e.message,
                toasterColor: "error",
              }),
            );
          })
      : dispatch(goToRoute("/login"));
  };
};
