import React from "react";

import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const References = ({ citations, handleSourceClick, getUrlType, moduleId }) => {
  return (
    <Box mt={2} mb={2}>
      <Typography variant="body2" fontWeight="bold" fontSize="small">
        References:
      </Typography>
      <Box
        mt={1}
        display={"flex"}
        flexDirection="column"
        alignContent="flex-start"
      >
        {citations.map((citation, index) => (
          <Box
            component="a"
            key={`reference-${index}`}
            href={citation.page_url}
            target={
              getUrlType(citation.page_url) === "https" ? "_blank" : undefined
            }
            rel="noopener noreferrer"
            sx={{
              textDecoration: "none",
              color: "inherit",
              display: "inline-flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: 1,
              px: 2,
              fontSize: "0.7rem",
              fontWeight: 600,
              cursor: "pointer",
              mx: 0.5,
              transition: "all 0.2s ease-in-out",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "rgb(227, 226, 224)",
              },
              lineHeight: 1,
              gap: 2,
              width: "fit-content",
            }}
            onClick={(e) => {
              if (getUrlType(citation.page_url) === "s3") {
                e.preventDefault();
                handleSourceClick(citation.source_id, moduleId);
              }
            }}
          >
            <Box
              component="span"
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: "primary.light",
                color: "primary.main",
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              {citation.citation_marker}
            </Box>
            {citation.file_name ||
              citation.page_url ||
              `Citation ${citation.citation_marker}`}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

References.propTypes = {
  citations: PropTypes.array.isRequired,
  handleSourceClick: PropTypes.func.isRequired,
  getUrlType: PropTypes.func.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default References;
