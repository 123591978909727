import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversations: [],
  loading: true,
  conversationCreated: false,
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    updateConversationsData: (state, action) => {
      state.conversations = action.payload;
      state.loading = false;
    },
    appendConversationsData: (state, action) => {
      state.conversations = [action.payload, ...state.conversations];
    },
    setConversationCreated: (state, action) => {
      state.conversationCreated = action.payload;
    },
    updateConversationTitle: (state, action) => {
      const { id, title } = action.payload;
      const conversation = state.conversations.find((convo) => convo.id === id);
      if (conversation) {
        conversation.title = title;
      }
    },
    deleteConversation: (state, action) => {
      state.conversations = state.conversations.filter(
        (convo) => convo.id !== action.payload,
      );
    },
  },
});

export const {
  updateConversationsData,
  appendConversationsData,
  setConversationCreated,
  updateConversationTitle,
  deleteConversation,
} = conversationSlice.actions;

export default conversationSlice.reducer;
