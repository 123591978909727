import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Paper,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";

import { customerPortal } from "@/api/subscription";
import PricingModal from "@/components/shared/Modals/PricingModal";
import getPricingPlanProps from "@/props-constants/PricingPlanProps";

const BillingDetails = ({ subscription }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pricingPlan = getPricingPlanProps(subscription.subscription_type);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleManageSubscription = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await customerPortal(accessToken);
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error managing subscription:", error);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ padding: 4, borderRadius: "1rem", border: "1px solid #b4caf7" }}
    >
      {subscription.subscription_type === "free" ? (
        <Box textAlign="center" mt={1}>
          <Typography variant="h3" gutterBottom color="black" sx={{ mb: 1 }}>
            Get unlimited usage <br />
            with
            <span
              style={{
                fontWeight: "700",
                background: "-webkit-linear-gradient(135deg, #76aeee, #7b3aa2)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              LRND
            </span>{" "}
            Premium
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
            sx={{
              marginTop: 1,
              padding: "10px 20px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Subscribe to Premium
          </Button>
          <Box sx={{ mt: 4 }}>
            <List
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                textAlign: "left",
                gap: 1,
              }}
            >
              {pricingPlan[1].features.map((feature, i) => (
                <ListItem
                  key={i}
                  sx={{ gap: "0.5rem", paddingTop: 0, paddingBottom: 0 }}
                >
                  <ListItemIcon sx={{ minWidth: 16 }}>
                    <CheckCircleOutlineIcon fontSize="24" />
                  </ListItemIcon>
                  <ListItemText
                    primary={feature}
                    primaryTypographyProps={{
                      variant: "body2",
                      fontSize: "14px",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      ) : (
        <Box sx={{ flex: 1, p: 4 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <AutoAwesomeIcon />
            <Typography variant="h5" component="h1">
              {pricingPlan[1].name}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            sx={{ mb: 2, mt: 2, fontWeight: "medium" }}
          >
            {pricingPlan[1].price}
          </Typography>
          <Button
            variant="outlined"
            disabled
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              fontSize: "medium",
            }}
          >
            Your current plan
          </Button>

          <Box sx={{ mt: 4 }}>
            {pricingPlan[1].features.map((feature, i) => (
              <Box key={i} display={"flex"} mt={2}>
                <CheckCircleOutlineIcon fontSize="small" />
                <Typography
                  variant="body2"
                  ml={1}
                  sx={{ fontWeight: "medium" }}
                >
                  {feature}
                </Typography>
              </Box>
            ))}
          </Box>

          <Button
            variant="text"
            onClick={handleManageSubscription}
            size="small"
            sx={{
              marginTop: 4,
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Manage Subscription
          </Button>
        </Box>
      )}

      {subscription.subscription_type === "free" && (
        <PricingModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          closable={true}
          subscription_key={subscription?.subscription_type}
          title={"Upgrade your plan"}
        />
      )}
    </Paper>
  );
};

BillingDetails.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default BillingDetails;
