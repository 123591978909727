// ResponsiveDrawer/index.js
import React from "react";

import { Box, Drawer, Toolbar, Divider } from "@mui/material";
import PropTypes from "prop-types";

// import UpgradeAction from "@/components/shared/ResponsiveDrawer/partials/UpdateAction";

import BaseSection from "./partials/BaseSection";
import UserActions from "./partials/UserActions";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const {
    window,
    mobileOpen,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    actionsList,
    onClickAction,
    shouldHighlight,
    userActions,
    // onUpdateClick,
    plan,
  } = props;

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        paddingLeft: "8px",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/logo-lrnd.png"
              alt="LRND Logo"
              style={{ width: "50%" }}
            />
            {/*<Typography variant="h6" component="h1" ml={2} sx={{fontWeight: 700}}>*/}
            {/*    LRND*/}
            {/*</Typography>*/}
          </Box>
        </Toolbar>
        <Divider />
        <BaseSection
          actionsList={actionsList}
          shouldHighlight={shouldHighlight}
          open
          onClickAction={onClickAction}
        />
      </Box>

      {/*<Divider />*/}
      {/*<UpgradeAction handleUpgrade={onUpdateClick} plan={plan} />*/}
      <Divider />
      <Box sx={{ flexShrink: 0 }}>
        <UserActions
          shouldHighlight={shouldHighlight}
          open
          onClickAction={onClickAction}
          userActions={userActions}
          plan={plan}
        />
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="navigation drawer"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        elevation={2}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  handleDrawerTransitionEnd: PropTypes.func.isRequired,
  actionsList: PropTypes.array.isRequired,
  onClickAction: PropTypes.func.isRequired,
  shouldHighlight: PropTypes.func.isRequired,
  // onUpdateClick: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
};

export default ResponsiveDrawer;
