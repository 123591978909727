// SuccessPage.js
import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { checkoutCheckoutStatus } from "@/api/subscription";
import Loader from "@/components/shared/Loader";
import { goToRoute } from "@/store/actions/navigationActions";
import { updateSubscriptionData } from "@/store/slices/subscriptionSlice"; // Import the Loader component

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { sessionId } = useParams();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await checkoutCheckoutStatus(accessToken, sessionId);
        if (response.data.status === "active") {
          dispatch(updateSubscriptionData(response.data));
          dispatch(goToRoute(`/profile`));
        } else {
          // handle other statuses or errors if necessary
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        // handle error accordingly
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      checkPaymentStatus();
    } else {
      setLoading(false);
    }
  }, [sessionId, dispatch]);

  return (
    <Box
      width="100%"
      height="80vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <Loader message={"Processing your payment..."} />
      ) : (
        <Typography variant="h6">
          Error processing payment. Please try again.
        </Typography>
      )}
    </Box>
  );
};

export default SuccessPage;
