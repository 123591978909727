import React, { useMemo, useCallback, Suspense } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";

import { addUrls, deleteSource, uploadFiles } from "@/api/sourceApi";
import Loader from "@/components/shared/Loader";
import useDialogState from "@/hooks/LearningMaterials/useDialogState";
import { useViewSources } from "@/hooks/LearningMaterials/useLearningMaterials"; // A loader component to display while dialogs are loading
import {
  getRawColumns,
  SourceTableProps,
  getLibrarySourceRowActions,
  getLibrarySourceButtonOptions,
} from "@/props-constants/source";
import {
  appendSourcesData,
  removeSource,
  setSourceLoading,
} from "@/store/slices/sourceSlice";
import { setToast } from "@/store/slices/toastSlice";
import { handleApiError } from "@/utils/common-utils";

// Lazy load the dialog components
const AddFilesDialog = React.lazy(
  () => import("@/components/shared/Modals/AddFilesDialog"),
);
const AddURLsDialog = React.lazy(
  () => import("@/components/shared/Modals/AddURLsDialog"),
);
const ConfirmationDialog = React.lazy(
  () => import("@/components/shared/Modals/ConfirmModal"),
);

const ListLibrary = () => {
  const {
    fileDialogOpen,
    urlDialogOpen,
    deleteDialogOpen,
    deletingSource,
    openFileDialog,
    closeFileDialog,
    openUrlDialog,
    closeUrlDialog,
    openDeleteDialog,
    closeDeleteDialog,
  } = useDialogState();

  const sources = useSelector((state) => state.sources.sources);
  const loading = useSelector((state) => state.sources.loading);

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { getAccessTokenSilently } = useAuth0();
  const handleSourceClick = useViewSources();

  const handleClick = useCallback(
    (option) => {
      if (option.key === "uploadFile") {
        openFileDialog();
      } else if (option.key === "addUrl") {
        openUrlDialog();
      }
    },
    [openFileDialog, openUrlDialog],
  );

  const handleRowOptionClick = useCallback(
    (row, option) => {
      if (option === "delete") {
        openDeleteDialog(row);
      } else if (option === "view") {
        if (row.original.source_type === "url") {
          window.open(row.original.url, "_blank", "noopener,noreferrer");
        } else {
          handleSourceClick(row.id);
        }
      }
    },
    [openDeleteDialog],
  );

  const handleDeleteConfirm = useCallback(async () => {
    if (!deletingSource) return;
    dispatch(setSourceLoading(true));
    try {
      const accessToken = await getAccessTokenSilently();
      await deleteSource(accessToken, deletingSource.original._id);

      dispatch(removeSource(deletingSource.original._id));
      dispatch(setSourceLoading(false));

      dispatch(
        setToast({
          message: "Successfully removed the source",
          toasterColor: "success",
        }),
      );
    } catch (error) {
      dispatch(setSourceLoading(false));
      handleApiError(error, dispatch, "Failed to delete the source");
    } finally {
      closeDeleteDialog();
    }
  }, [deletingSource, dispatch, getAccessTokenSilently, closeDeleteDialog]);

  const handleUploadFiles = useCallback(
    async (acceptedFiles) => {
      dispatch(setSourceLoading(true));
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });

      try {
        const accessToken = await getAccessTokenSilently();
        const response = await uploadFiles(accessToken, formData);

        dispatch(setSourceLoading(false));
        dispatch(appendSourcesData(response.data));

        dispatch(
          setToast({
            message: "Files uploaded successfully.",
            toasterColor: "success",
          }),
        );
      } catch (error) {
        handleApiError(error, dispatch, "Failed to upload the file");
      } finally {
        dispatch(setSourceLoading(false));
        closeFileDialog();
      }
    },
    [dispatch, getAccessTokenSilently, closeFileDialog],
  );

  const handleAddUrl = useCallback(
    async (urlList) => {
      const payload = { urls: urlList };
      dispatch(setSourceLoading(true));
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await addUrls(accessToken, payload);
        dispatch(appendSourcesData(response.data));

        dispatch(
          setToast({
            message: "URLs added successfully.",
            toasterColor: "success",
          }),
        );
      } catch (error) {
        console.log(error);
        handleApiError(error, dispatch, "Failed to add the url");
      } finally {
        dispatch(setSourceLoading(false));
        closeUrlDialog();
      }
    },
    [dispatch, getAccessTokenSilently, closeUrlDialog],
  );

  const columns = useMemo(() => getRawColumns(isMobile), [isMobile]);

  const table = useMaterialReactTable({
    columns,
    data: sources,
    ...SourceTableProps(
      sources,
      isMobile,
      loading,
      getLibrarySourceButtonOptions(),
      handleClick,
      getLibrarySourceRowActions(handleRowOptionClick),
    ),
  });

  return (
    <Box sx={{ padding: "0px", display: "flex", flexDirection: "column" }}>
      <MaterialReactTable table={table} />
      <Suspense fallback={<Loader message="Loading Add Files Dialog..." />}>
        <AddFilesDialog
          open={fileDialogOpen}
          handleClose={closeFileDialog}
          handleUploadFile={handleUploadFiles}
          uploading={loading}
        />
      </Suspense>

      <Suspense fallback={<Loader message="Loading Add URLs Dialog..." />}>
        <AddURLsDialog
          open={urlDialogOpen}
          handleClose={closeUrlDialog}
          handleAddUrl={handleAddUrl}
          loading={loading}
        />
      </Suspense>

      <Suspense fallback={<Loader message="Loading Confirmation Dialog..." />}>
        <ConfirmationDialog
          open={deleteDialogOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={closeDeleteDialog}
          title="Confirm Delete"
          description="Are you sure you want to remove this Source? "
          secondaryText="This action cannot be undone. This will remove the source from any modules it has been added to."
          loading={loading}
        />
      </Suspense>
    </Box>
  );
};

export default ListLibrary;
