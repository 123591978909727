import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { shareModule, unShareModule } from "@/api/moduleApi";
import Loader from "@/components/shared/Loader";
import ConfirmDialog from "@/components/shared/Modals/ShareModal/partials/ConfirmDialog";
import { setToast } from "@/store/slices/toastSlice";

import ShareDialogActions from "./partials/ShareDialogActions";
import ShareDialogContent from "./partials/ShareDialogContent";
import ShareDialogTitle from "./partials/ShareDialogTitle";

const ShareDialog = ({ open, onClose, module, title, sharedList, owner }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [addedEmails, setAddedEmails] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sharedUsers, setSharedUsers] = useState([]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setSharedUsers(module?.shared_with_details);
  }, [module]);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleAddEmail = (email) => {
    const isEmailAlreadyShared = sharedList.some(
      (user) => user.email === email,
    );
    if (email && validateEmail(email) && !isEmailAlreadyShared) {
      if (!addedEmails.includes(email)) {
        setAddedEmails((prev) => [...prev, email]);
        setEmail("");
        setEmailError("");
      } else {
        setEmailError("This email has already been added");
      }
    } else if (isEmailAlreadyShared) {
      setEmailError("This email already has access");
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const handleAddAllEmails = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const payload = { emails: addedEmails };
      setEmail("");

      const data = await shareModule(token, module._id, payload);
      setSharedUsers(data.data.shared_with_details);
      setAddedEmails([]);
      dispatch(
        setToast({
          message: `Shared the access with ${addedEmails.join(", ")}`,
          toasterColor: "success",
        }),
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e.message,
          toasterColor: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setAddedEmails(addedEmails.filter((email) => email !== emailToDelete));
  };

  const handleRemoveAccess = async (email) => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const payload = { email: email };
      const data = await unShareModule(token, module._id, payload);
      setSharedUsers(data.data.shared_with_details);
      dispatch(
        setToast({
          message: `Removed access for ${email}`,
          toasterColor: "success",
        }),
      );
    } catch (error) {
      dispatch(
        setToast({
          message: error.message,
          toasterColor: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleShare = () => {
    if (addedEmails.length > 0) {
      setConfirmationOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setLoading(false);
    setEmail("");
    setAddedEmails([]);
    setEmailError("");
    onClose();
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    handleClose();
  };

  const handleConfirmShare = async () => {
    setConfirmationOpen(false);
    handleAddAllEmails().then(() => {
      handleClose();
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            width: "60%",
            maxWidth: "90vw",
            height: "650px",
            maxHeight: "90vh",
            borderRadius: "16px",
          },
        }}
      >
        <ShareDialogTitle title={title} handleClose={handleClose} />
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Loader message={"Loading"} />
          </Box>
        ) : (
          <>
            <ShareDialogContent
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              handleAddEmail={handleAddEmail}
              addedEmails={addedEmails}
              handleDeleteEmail={handleDeleteEmail}
              sharedUsers={sharedUsers}
              handleRemoveAccess={handleRemoveAccess}
              handleAddAllEmails={handleAddAllEmails}
              setEmailError={setEmailError}
              owner={owner}
            />
            <ShareDialogActions handleShare={handleShare} />
          </>
        )}
      </Dialog>
      <ConfirmDialog
        confirmationOpen={confirmationOpen}
        handleConfirmationClose={handleConfirmationClose}
        handleConfirmShare={handleConfirmShare}
      />
    </>
  );
};

export default ShareDialog;
