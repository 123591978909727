import constants from "@/constants";

import { createAxiosInstance } from "./axiosInstance";

const createApiInstance = (
  accessToken,
  header = null,
  responseType = "json",
) => {
  return createAxiosInstance(
    constants.ragApi,
    accessToken,
    header,
    responseType,
  );
};

export default createApiInstance;
