//Message.jsx
import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { Box, useTheme, Tooltip, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { handleMessageReaction } from "@/api/conversation";
import { useViewSources } from "@/hooks/LearningMaterials/useLearningMaterials";
import { setToast } from "@/store/slices/toastSlice";

import MarkdownContent from "./MarkdownContent";
import References from "./References";

import "./styles.css";
// eslint-disable-next-line import/order
import sources from "@/props-constants/ExpandingInputProps";

const Message = ({ message, dialogRef, moduleId }) => {
  const { content, citations, role, id, feedback, qaType } = message;

  const theme = useTheme();
  const isUser = role === "user";

  const [isHovered, setIsHovered] = useState(false);
  const [userReactionState, setUserReactionState] = useState(
    feedback?.reaction || 0,
  );

  const handleSourceClick = useViewSources();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const getUrlType = (url) => {
    if (url.startsWith("https://")) return "https";
    if (url.startsWith("s3://")) return "s3";
    return "unknown";
  };

  // Process content for citations
  const processedContent = content.replace(
    /\[(\d+)\]/g,
    (_, number) => `<<CITATION_${number}>>`,
  );

  const handleThumbsUp = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const payload = { reaction: 1 };
      await handleMessageReaction(accessToken, id, payload);
      setUserReactionState(1);
      dispatch(
        setToast({
          message: "Thanks for the feedback",
          toasterColor: "success",
        }),
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.error?.message || "Failed to add feedback",
          toasterColor: "error",
        }),
      );
    }
  };

  const handleThumbsDown = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const payload = { reaction: -1 };
      await handleMessageReaction(accessToken, id, payload);
      setUserReactionState(-1);
      dispatch(
        setToast({
          message: "Thanks for the feedback",
          toasterColor: "success",
        }),
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.error?.message || "Failed to add feedback",
          toasterColor: "error",
        }),
      );
    }
  };

  const handleCopy = () => {
    // Remove citations enclosed in square brackets
    const cleanedContent = content.replace(/\[\d+\]/g, "").trim();
    navigator.clipboard
      .writeText(cleanedContent)
      .then(() => {
        dispatch(
          setToast({
            message: "Copied",
            toasterColor: "success",
          }),
        );
      })
      .catch((e) => {
        dispatch(
          setToast({
            message: e.message,
            toasterColor: "error",
          }),
        );
      });
  };

  const selectedSourceDetails =
    sources.find((source) => source.key === qaType) || sources[0];

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: isUser ? "flex-end" : "flex-start",
        mb: 2,
        backgroundColor: isUser
          ? "rgb(243, 244, 246)"
          : theme.palette.common.white,
        borderRadius: "16px",
        padding: "12px 16px",
        paddingBottom: isUser ? "16px" : "32px",
        maxWidth: isUser ? "75%" : "100%",
        fontWeight: 500,
        "&:hover": {
          backgroundColor: !isUser
            ? "rgba(53, 55, 47, 0.04)"
            : "rgb(243, 244, 246)",
        },
        fontFamily: isUser
          ? "inherit"
          : "ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI Variable Display', 'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol'",
        position: "relative",
      }}
    >
      <MarkdownContent
        content={processedContent}
        citations={citations}
        dialogRef={dialogRef}
      />
      {!isUser && citations?.length > 0 && (
        <References
          citations={citations}
          handleSourceClick={handleSourceClick}
          getUrlType={getUrlType}
          moduleId={moduleId}
        />
      )}
      {/* Icons Section */}
      {!isUser && isHovered && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            position: "absolute",
            bottom: 8,
            right: 16,
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.3s",
          }}
        >
          {selectedSourceDetails && (
            <>
              {selectedSourceDetails.icon}
              <Typography fontSize={14}>
                {selectedSourceDetails.buttonText}
              </Typography>
            </>
          )}
          <Tooltip title="Copy">
            <IconButton size="small" onClick={handleCopy}>
              <ContentCopyIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Tooltip>
          {/*{dialogRef && (*/}
          {/*    <Tooltip title={"Save to Note"}>*/}
          {/*        <IconButton size="small" onClick={handleSaveToNote}>*/}
          {/*            <SaveAltOutlinedIcon sx={{ fontSize: 18 }}></SaveAltOutlinedIcon>*/}
          {/*        </IconButton>*/}
          {/*    </Tooltip>*/}
          {/*)}*/}
          {userReactionState === 1 ? (
            <Tooltip title="You liked this response">
              <IconButton size="small" color="primary">
                <ThumbUpIcon sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>
          ) : userReactionState === -1 ? (
            <Tooltip title="You disliked this response">
              <IconButton size="small" color="error">
                <ThumbDownIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Good Response">
                <IconButton size="small" onClick={handleThumbsUp}>
                  <ThumbUpAltOutlinedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bad Response">
                <IconButton size="small" onClick={handleThumbsDown}>
                  <ThumbDownOffAltOutlinedIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Message;
