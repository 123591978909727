import instance from "./instances/ragBackend";

export function getConversations(accessToken) {
  return instance(accessToken).get("/v1/conversation");
}

export function getModuleConversations(accessToken, moduleId) {
  return instance(accessToken).get(`/v1/conversation/module/${moduleId}`);
}

export function getMessages(conversation_id, accessToken) {
  return instance(accessToken).get(`/v1/conversation/${conversation_id}`);
}

export function getConversation(conversation_id, accessToken) {
  return instance(accessToken).get(`/v1/conversation/${conversation_id}`);
}

export function createConversation(accessToken, payload) {
  return instance(accessToken).post("/v1/conversation", payload);
}

export function sendMessage(payload, accessToken) {
  return instance(accessToken).post("/v1/message", payload);
}

export function renameConversation(payload, accessToken, cId) {
  return instance(accessToken).patch(`/v1/conversation/${cId}`, payload);
}

export function deleteConversationApi(accessToken, cId) {
  return instance(accessToken).delete(`/v1/conversation/${cId}`);
}

export function handleMessageReaction(accessToken, messageId, payload) {
  return instance(accessToken).put(
    `/v1/message/${messageId}/feedback`,
    payload,
  );
}
