import creditReducer from "@/store/slices/creditSlice";
import sourceReducer from "@/store/slices/sourceSlice";
import subscriptionReducer from "@/store/slices/subscriptionSlice";

import conversationReducer from "./slices/conversationSlice";
import toasterReducer from "./slices/toastSlice";

const rootReducer = (routerReducer) => ({
  router: routerReducer,
  subscription: subscriptionReducer,
  toaster: toasterReducer,
  sources: sourceReducer,
  conversations: conversationReducer,
  credits: creditReducer,
});

export default rootReducer;
