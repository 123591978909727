// NavigationMenu.js
import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import PricingModal from "@/components/shared/Modals/PricingModal";
import ResponsiveDrawer from "@/components/shared/ResponsiveDrawer";
import getNavigationDrawerProps from "@/props-constants/NavigationDrawerProps";

const drawerWidth = 260;

const NavigationMenu = ({ children }) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.router.location);
  const subscription = useSelector((state) => state.subscription.subscription);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isClosable, setIsClosable] = useState(true);

  useEffect(() => {
    if (Object.keys(subscription).length === 0) {
      setOpenModal(true);
      setIsClosable(false);
    }
  }, [subscription]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const shouldHighlightNavigationAction = (action) => {
    if (action.goto === location.pathname) {
      return true;
    } else if (
      action.matchPath &&
      action.matchPath === location.pathname.split("/")[1]
    ) {
      return true;
    } else if (action.path === location.pathname) return true;
    return false;
  };

  const onClickAction = (action) => {
    action.goto && action.goto();
  };

  const NavigationDrawerProps = getNavigationDrawerProps(dispatch);

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  //   setIsClosable(true);
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            LRND
          </Typography>
        </Toolbar>
      </AppBar>
      <ResponsiveDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        handleDrawerClose={handleDrawerClose}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        actionsList={NavigationDrawerProps.actionsList}
        shouldHighlight={shouldHighlightNavigationAction}
        onClickAction={onClickAction}
        userActions={NavigationDrawerProps.userActions}
        // onUpdateClick={handleOpenModal}
        plan={subscription?.subscription_type}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <CssBaseline />
        <Toolbar
          sx={{
            display: { sm: "none" },
          }}
        />
        {children}
      </Box>
      <PricingModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        closable={isClosable}
        subscription_key={subscription?.subscription_type}
        title={!isClosable ? "Select a plan to continue" : "Upgrade your plan"}
      />
    </Box>
  );
};

export default NavigationMenu;
