import React from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import "./iconStyles.css";

export default function getIcon(
  iconType,
  color = "inherit",
  size = "medium",
  className = "",
  weight = 700,
) {
  switch (iconType) {
    case "dashboard":
      return (
        <DashboardIcon
          color={color}
          className={className}
          sx={{ fontSize: 24, height: "1rem", width: "1rem" }}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "user-management":
      return (
        <ManageAccountsIcon
          color={color}
          className={className}
          fontSize={size}
        />
      );

    case "conversations":
      return (
        <QuestionAnswerIcon
          color={color}
          className={className}
          fontSize={size}
        />
      );
    case "thumbs_up":
      return (
        <ThumbUpIcon color={color} className={className} fontSize={size} />
      );
    case "thumbs_down":
      return (
        <ThumbDownIcon color={color} className={className} fontSize={size} />
      );
    case "done":
      return (
        <DoneOutlinedIcon color={color} className={className} fontSize={size} />
      );
    case "clear":
      return <ClearIcon color={color} className={className} fontSize={size} />;
    case "warning":
      return (
        <ErrorOutlineOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
        />
      );

    case "leftArrow":
      return (
        <ChevronLeftIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "rightArrow":
      return (
        <ChevronRightIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "questionAnswerRound":
      return (
        <QuestionAnswerRoundedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "person":
      return (
        <PersonIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );
    case "exit":
      return (
        <ExitToAppIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
          sx={{ fontSize: 24, height: "1rem", width: "1rem" }}
        />
      );

    case "chat":
      return (
        <ChatIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "edit":
      return (
        <EditIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "delete":
      return (
        <DeleteIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "add":
      return (
        <AddCircleOutlineIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "source":
      return (
        <LibraryAddIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
          sx={{ fontSize: 24, height: "1rem", width: "1rem" }}
        />
      );

    case "arrowDown":
      return (
        <ArrowDropDownIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "settings":
      return (
        <SettingsOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "feature_request":
      return (
        <SettingsOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "help_center":
      return (
        <HelpOutlineOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "arrowUp":
      return (
        <ExpandLessOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "redirect":
      return (
        <OpenInNewOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "help":
      return (
        <QuizOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    case "bug":
      return (
        <BugReportOutlinedIcon
          color={color}
          className={className}
          fontSize={size}
          fontWeight={weight}
        />
      );

    default:
      console.log(`Warning: icon for ${iconType} not found!`);
      return <div>{iconType}</div>;
  }
}
