import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

import { formatDate } from "@/utils/helperUtils";

const GeneralGroupedDrawer = ({
  open,
  onClose,
  data,
  onSearch,
  onItemSelect,
  title = "Drawer Title",
  loading = false,
  groupBy = "created_at",
  onNewClick,
  itemIcon,
  newItemIcon,
  newItemText = "New",
  currentItem,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (onSearch) onSearch(e.target.value); // Notify parent of search changes
  };

  // Function to group data
  const groupData = (items) => {
    const groups = {
      Today: [],
      Yesterday: [],
      "Past Week": [],
      "1 Month": [],
      Older: [],
    };

    const now = new Date();
    const todayStart = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    );
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(todayStart.getDate() - 1);
    const oneWeekAgo = new Date(todayStart);
    oneWeekAgo.setDate(todayStart.getDate() - 7);
    const oneMonthAgo = new Date(todayStart);
    oneMonthAgo.setDate(todayStart.getDate() - 30);

    items.forEach((item) => {
      const itemDate = new Date(item[groupBy]);

      if (itemDate >= todayStart) {
        groups.Today.push(item);
      } else if (itemDate >= yesterdayStart && itemDate < todayStart) {
        groups.Yesterday.push(item);
      } else if (itemDate >= oneWeekAgo && itemDate < yesterdayStart) {
        groups["Past Week"].push(item);
      } else if (itemDate >= oneMonthAgo && itemDate < oneWeekAgo) {
        groups["1 Month"].push(item);
      } else {
        groups.Older.push(item);
      }
    });

    return groups;
  };

  // Filter data based on search query
  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const groupedData = groupData(filteredData);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: 450,
          padding: 2,
        },
      }}
    >
      {/* Drawer Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" fontWeight="medium" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Tooltip title={newItemText}>
            <IconButton onClick={onNewClick} size={"small"}>
              {newItemIcon}
            </IconButton>
          </Tooltip>
          <IconButton onClick={onClose} size={"small"}>
            <CloseIcon fontSize="14" />
          </IconButton>
        </Box>
      </Box>

      {/* Search Bar */}
      <TextField
        fullWidth
        placeholder="Search..."
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        size="small"
        sx={{
          borderRadius: "8px",
          marginBottom: 2,
        }}
        inputProps={{
          sx: {
            fontSize: "12px",
            padding: "8px",
          },
        }}
      />

      {/* Loading State */}
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography>Loading...</Typography>
        </Box>
      ) : (
        // Grouped Data List
        <Box sx={{ overflowY: "auto" }}>
          {Object.keys(groupedData).map(
            (group) =>
              groupedData[group].length > 0 && (
                <Box key={group} mb={2}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    sx={{
                      mb: 2,
                      fontSize: "12px",
                      color: "rgba(55, 53, 47, 0.65)",
                    }}
                  >
                    {group}
                  </Typography>
                  {groupedData[group].map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="flex-start"
                      gap={2}
                      alignItems="center"
                      sx={{
                        color: "inherit",
                        py: 2,
                        px: 2,
                        mb: 1,
                        fontSize: "0.7rem",
                        fontWeight: 600,
                        cursor: "pointer",
                        transition: "all 0.2s ease-in-out",
                        borderRadius: 2,
                        backgroundColor:
                          currentItem === item.id
                            ? "rgba(0, 120, 212, 0.1)" // Highlight color
                            : "inherit",
                        border:
                          currentItem === item.id
                            ? "1px solid rgba(0, 120, 212, 0.5)" // Highlight border
                            : "none",
                        "&:hover": {
                          backgroundColor: "rgb(227, 226, 224)",
                        },
                      }}
                      onClick={() => onItemSelect(item.id)}
                    >
                      {itemIcon}

                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <Typography
                          variant="body2"
                          fontWeight="medium"
                          fontFamily={
                            'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"'
                          }
                          color={"rgb(55, 53, 47)"}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: "rgb(150, 150, 150)" }}
                        >
                          {formatDate(item.created_at)}
                        </Typography>
                      </Box>
                      {/*<Typography variant="body2">{item.title}</Typography>*/}
                    </Box>
                  ))}
                </Box>
              ),
          )}
          {/* No Results */}
          {Object.values(groupedData).every((group) => group.length === 0) && (
            <Typography textAlign="center" color="textSecondary" mt={4}>
              No results found
            </Typography>
          )}
        </Box>
      )}
    </Drawer>
  );
};

GeneralGroupedDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSearch: PropTypes.func,
  onItemSelect: PropTypes.func.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
  groupBy: PropTypes.string,
  onNewClick: PropTypes.func,
  currentItem: PropTypes.string,
  newItemText: PropTypes.string,
  newItemIcon: PropTypes.node,
};

export default GeneralGroupedDrawer;
