import React, { useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { RxChatBubble } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getModuleConversations, renameConversation } from "@/api/conversation";
import { getModuleDetails } from "@/api/moduleApi";
import ConversationHeader from "@/components/modules/Conversation/partials/Header";
import ExpandingTextInput from "@/components/shared/ExpandingTextInput";
import GeneralGroupedDrawer from "@/components/shared/GeneralDrawer";
import Loader from "@/components/shared/Loader";
import MessageList from "@/components/shared/MessageList";
import useMessageHandler from "@/hooks/Message/useMessageHandler";
import { goToRoute } from "@/store/actions/navigationActions";
import { setToast } from "@/store/slices/toastSlice";
import theme from "@/utils/theme";

const Conversation = () => {
  const { moduleId, conversationId } = useParams();
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const [moduleDetails, setModuleDetails] = useState(null);
  const [version, setVersion] = useState("1");
  const [isModel4o, setIsModel4o] = useState(false);
  const messagesContainerRef = useRef(null);

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);

  const {
    messages,
    setMessages,
    input,
    isBotTyping,
    loading,
    conversationCreated,
    setInput,
    fetchMessages,
    sendMessage,
    shouldScrollRef,
    setConId,
    setQaType,
    qaType,
  } = useMessageHandler(
    moduleId,
    conversationId,
    moduleDetails,
    version,
    isModel4o,
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (shouldScrollRef.current) {
      scrollToBottom();
      shouldScrollRef.current = false;
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const fetchConversations = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getModuleConversations(accessToken, moduleId);
      setConversations(response.data);
    } catch (e) {
      dispatch(
        setToast({
          message:
            e.response?.data?.error?.message || "Failed to load the history",
        }),
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    fetchConversations().then(() => {
      setCurrentConversation(
        conversations.find((conv) => conv.id === conversationId),
      );
    });
  }, [moduleId]);

  useEffect(() => {
    if (conversationId && !conversationCreated) {
      setConId(conversationId);
      fetchMessages({ conversationId });
      if (conversations.length > 0) {
        setCurrentConversation(
          conversations.find((conv) => conv.id === conversationId),
        );
      }
    }
  }, [conversationId, conversationCreated]);

  useEffect(() => {
    const getModuleInfo = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getModuleDetails(accessToken, moduleId);
        setModuleDetails(response.data);
      } catch (error) {
        dispatch(
          setToast({
            message:
              error.response.data.error.message ||
              "Failed to get the module details",
            toasterColor: "error",
          }),
        );
      }
    };
    getModuleInfo();
  }, [getAccessTokenSilently, moduleId]);

  const handleInputChange = (input) => {
    setInput(input);
  };

  const handleNewChatClicked = () => {
    if (messages.length > 0) {
      setMessages([]);
      setConId(null);
      setCurrentConversation(null);
      setDrawerOpen(false);
      dispatch(goToRoute(`/module/${moduleId}/c`));
    }
  };

  const handleBack = () => {
    dispatch(goToRoute(`/module/${moduleId}/d`));
  };

  const handleSelectItem = async (id) => {
    setConId(id);
    if (conversationCreated) {
      await fetchMessages({ id });
    }
    setDrawerOpen(false); // Close drawer after selection
    dispatch(goToRoute(`/module/${moduleId}/c/${id}`));
  };

  const handleTitleUpdate = async (title, chatId) => {
    const newTitle = title;
    try {
      const accessToken = await getAccessTokenSilently();
      const payload = { title: newTitle };

      const response = await renameConversation(payload, accessToken, chatId);

      if (response.data) {
        // Update local state with the new title
        setConversations((prevConversations) =>
          prevConversations.map((conversation) =>
            conversation.id === chatId
              ? { ...conversation, title: newTitle }
              : conversation,
          ),
        );
        dispatch(
          setToast({
            message: `Renamed conversation successfully to ${newTitle}`,
            toasterColor: "success",
          }),
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          message: error.message,
          toasterColor: "error",
        }),
      );
    }
  };

  const openDrawer = () => {
    fetchConversations();
    setDrawerOpen(true);
  };

  if (loading) {
    return <Loader message={"Loading conversation"} />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "90vh" : "92vh",
          maxHeight: "100vh",
          backgroundColor: "#FFF",
          overflowY: "auto",
          scrollBehavior: "smooth",
        }}
      >
        <ConversationHeader
          moduleName={moduleDetails?.name ? moduleDetails.name : "Tutor"}
          conversation={currentConversation}
          handleNewChatClicked={handleNewChatClicked}
          handleBack={handleBack}
          version={version}
          setVersion={setVersion}
          isModel4o={isModel4o}
          setIsModel4o={setIsModel4o}
          handleDrawerOpen={openDrawer}
          handleTitleUpdate={handleTitleUpdate}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: { xs: 1, sm: 2 },
            width: "70%",
            margin: "0 auto",
            gap: 2,
            marginBottom: "24px",
          }}
        >
          <Box
            ref={messagesContainerRef}
            sx={{
              flexGrow: 1,
              padding: { xs: 1, sm: 3 },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {messages.length === 0 ? (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <SupervisorAccountOutlinedIcon
                  sx={{ fontSize: 56 }}
                  color="secondary"
                />
                <Typography variant="h5" component="p" align="center">
                  How can I help you today?
                </Typography>
              </Box>
            ) : (
              <MessageList
                messages={messages}
                isBotTyping={isBotTyping}
                userName={user?.name}
                dialogRef={null}
                moduleId={moduleId}
                qaType={qaType}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "80%",
            backgroundColor: "#FFF",
            padding: "8px 16px",
            paddingBottom: "24px",
            zIndex: 1000,
          }}
        >
          <Box sx={{ width: "70%", margin: "auto" }}>
            <ExpandingTextInput
              userInput={input}
              onInputChange={handleInputChange}
              onSend={sendMessage}
              setQaType={setQaType}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              textAlign={"center"}
              sx={{ mt: 2 }}
            >
              Lrnd.ai can make mistakes. Please double check responses.
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* Add GeneralDrawer */}
      <GeneralGroupedDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        data={conversations}
        onItemSelect={handleSelectItem}
        title="Conversation History"
        newButtonLabel="New Chat"
        onNewClick={handleNewChatClicked}
        itemIcon={<RxChatBubble fontSize="14" strokeWidth={"0.01em"} />}
        newItemIcon={<RxChatBubble fontSize="18" strokeWidth={"0.02em"} />}
        newItemText="New Chat"
        groupBy={"updated_at"}
        currentItem={conversationId}
      />
    </>
  );
};

export default Conversation;
