import React, { useState } from "react";

import AllInbox from "@mui/icons-material/AllInbox";
import EmailIcon from "@mui/icons-material/Email";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import {
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  Chip,
  Divider,
  InputAdornment,
  Avatar,
  IconButton,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const ShareDialogContent = ({
  email,
  setEmail,
  emailError,
  setEmailError,
  handleAddEmail,
  addedEmails,
  handleDeleteEmail,
  sharedUsers,
  handleRemoveAccess,
  handleAddAllEmails,
  owner,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  return (
    <DialogContent dividers>
      <Box display="flex" alignItems="center" mb={3}>
        <Button
          startIcon={<AllInbox sx={{ color: "blue" }} />}
          sx={{
            textTransform: "none",
            backgroundColor: "#F0F0FF",
            color: "blue",
            mr: 2,
          }}
          onClick={() => {}}
        >
          Collaborate
        </Button>
      </Box>
      <Box display="flex" alignItems="flex-start" mb={2}>
        <Autocomplete
          freeSolo
          fullWidth
          openOnFocus={false}
          options={email ? [...new Set([email, ...addedEmails])] : []}
          inputValue={email}
          value={autocompleteValue}
          onInputChange={(event, newInputValue) => {
            setEmail(newInputValue);
            setEmailError("");
          }}
          onChange={(event, newValue) => {
            if (newValue) {
              handleAddEmail(newValue);
              setAutocompleteValue(null);
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              p={3}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F0F0FF",
                borderRadius: 1,
                marginBottom: 1.5,
                marginRight: 3,
                marginLeft: 3,
              }}
            >
              <EmailIcon sx={{ mr: 1, mt: 1, mb: 1 }} />
              <Typography>{option}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder="Add emails"
              size="small"
              error={!!emailError}
              helperText={emailError}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          variant="contained"
          startIcon={<ShareIcon sx={{ width: "0.85rem", height: "0.85rem" }} />}
          size="small"
          disableElevation
          sx={{
            textTransform: "none",
            borderRadius: "8px",
            marginLeft: "24px",
            padding: "4px 16px",
          }}
          aria-label="Share the document"
          onClick={() => {
            handleAddAllEmails();
            setAutocompleteValue(null);
          }}
          disabled={addedEmails.length === 0}
        >
          Share
        </Button>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
        {addedEmails.map((addedEmail) => (
          <Chip
            key={addedEmail}
            icon={<EmailIcon />}
            label={addedEmail}
            onDelete={() => handleDeleteEmail(addedEmail)}
          />
        ))}
      </Box>
      <Divider variant="middle" />
      <Typography variant="h4" component="div" sx={{ mt: 2, mb: 1 }}>
        Shared with
      </Typography>
      {sharedUsers &&
        sharedUsers.map((user, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={user.picture}
                alt={user.name}
              />
              <Box>
                <Typography variant="subtitle2">{user.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {user.email}
                </Typography>
              </Box>
            </Box>
            {owner === true && (
              <>
                <IconButton
                  size={"small"}
                  onClick={() => handleRemoveAccess(user.email)}
                  color={"primary"}
                >
                  <PersonRemoveOutlinedIcon fontSize={"small"} />
                </IconButton>
                {/*<Button*/}
                {/*    variant="outlined"*/}
                {/*    size="small"*/}
                {/*    sx={{*/}
                {/*        borderRadius: '8px',*/}
                {/*        textTransform: 'none',*/}
                {/*    }}*/}
                {/*    startIcon={<PersonRemoveIcon />}*/}
                {/*    onClick={() => handleRemoveAccess(user.email)}*/}
                {/*>*/}
                {/*    Remove*/}
                {/*</Button>*/}
              </>
            )}
          </Box>
        ))}
    </DialogContent>
  );
};

export default ShareDialogContent;
