import { setToast } from "@/store/slices/toastSlice";

const getRandomLightColor = (theme) => {
  const lightColors = [
    theme.palette.primary.light,
    theme.palette.secondary.light,
    theme.palette.tertiary.light,
    "#fff3e0", // Lighter orange
    "#e0f7fa", // Lighter cyan
    "#ede7f6", // Lighter purple
    "#e8f5e9", // Lighter green
    "#ffe5dd", // Lighter peach
    "#fce4ec", // Lighter pink
    "#fff9c4", // Lighter yellow
    // theme.palette.background.default, // Adding the background light color as well
  ];
  return lightColors[Math.floor(Math.random() * lightColors.length)];
};

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

const handleApiError = (error, dispatch, defaultMessage) => {
  dispatch(
    setToast({
      message: error.response?.data?.error?.message || defaultMessage,
      toasterColor: "error",
    }),
  );
};

const getUrlType = (url) => {
  if (url.startsWith("https://")) {
    return "https";
  } else if (url.startsWith("s3://")) {
    return "s3";
  } else {
    return "Unknown URL type";
  }
};

export { getRandomLightColor, handleApiError, getUrlType };
