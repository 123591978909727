import React, { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
  CardActions,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";

import GeneralMenu from "@/components/shared/GeneralMenu";

const GeneralCard = ({
  title,
  createdAt,
  user,
  ownerId,
  createdByDetail,
  onCardClick,
  mediaHeight = 140,
  mediaBackgroundColor,
  menuItems = [],
  additionalChips = [],
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: isSmallScreen ? "100%" : 345,
        borderRadius: 2,
        boxShadow: 3,
        mb: 3,
        mr: isSmallScreen ? 0 : 3,
      }}
    >
      <CardActionArea onClick={onCardClick}>
        <CardMedia
          component="div"
          sx={{
            height: mediaHeight,
            backgroundColor: mediaBackgroundColor || "lightgray",
          }}
          alt="Card Cover"
        />
        <CardContent sx={{ mt: 1 }}>
          <Tooltip title={title}>
            <Typography
              gutterBottom
              component="div"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "3em",
                fontSize: isSmallScreen ? "0.875rem" : "1rem",
              }}
            >
              {title}
            </Typography>
          </Tooltip>

          <Box
            display="flex"
            alignItems="center"
            mt={1}
            mb={1}
            justifyContent={
              additionalChips.length > 1 ? "space-between" : "flex-start"
            }
            // flexDirection={isSmallScreen ? 'column' : 'row'}

            gap={isSmallScreen ? 1 : 2}
          >
            {additionalChips.map((chip, index) => (
              <Tooltip title={chip.tooltip || ""} key={index}>
                <Chip
                  label={chip.label}
                  icon={chip.icon}
                  sx={{
                    backgroundColor: chip.backgroundColor,
                    color: chip.textColor || "white",
                    borderRadius: "0.5rem",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    fontSize: isSmallScreen ? "0.65rem" : "0.75rem", // Responsive chip font size
                  }}
                />
              </Tooltip>
            ))}
          </Box>
        </CardContent>
      </CardActionArea>

      <CardActions
        sx={{ justifyContent: "space-between", padding: "4px", ml: 1 }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ flexDirection: "row" }}
          mb={2}
        >
          <Avatar
            sx={{
              width: 24,
              height: 24,
              mr: 2,
              backgroundColor: theme.palette.primary.light,
            }}
            src={createdByDetail.picture}
            alt={createdByDetail.name}
          />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              textTransform="capitalize"
              sx={{
                fontSize: isSmallScreen ? "0.75rem" : "0.875em",
              }}
            >
              {user.sub === ownerId
                ? "Created by You"
                : `Created by ${createdByDetail.name}`}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontSize: isSmallScreen ? "0.65rem" : "0.75em",
              }}
            >
              Created at: {createdAt}
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          aria-label="more"
          sx={{ ml: 2 }}
          aria-controls={anchorEl ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? "true" : undefined}
          onClick={handleMenuOpen}
          // onClick={(event) => handleMenuClick(event, knowledgeBase)}
          // disabled={knowledgeBase.status !== 'complete'}
        >
          <MoreVertIcon fontSize="12px" />
        </IconButton>
      </CardActions>
      {/* General Menu */}
      <GeneralMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        menuItems={menuItems} // Pass menu items and handlers
        selectedItem={{ title, createdAt, createdBy: createdByDetail.name }}
      />
    </Card>
  );
};

GeneralCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  onCardClick: PropTypes.func,
  mediaHeight: PropTypes.number,
  mediaBackgroundColor: PropTypes.string,
  additionalChips: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      icon: PropTypes.element,
      backgroundColor: PropTypes.string,
      textColor: PropTypes.string,
    }),
  ),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
      color: PropTypes.string,
      disabled: PropTypes.bool,
      divider: PropTypes.bool,
      hidden: PropTypes.bool,
    }),
  ),
};

export default GeneralCard;
