import React from "react";

import Conversation from "@/components/modules/Conversation";
import ListLibrary from "@/components/modules/Library/ListLibrary";
import Editor from "@/components/modules/Notes/EditorV2";
import SuccessPage from "@/components/modules/PaymentSuccess";
import StudentModule from "@/components/modules/StudentModules";
import ViewModule from "@/components/modules/StudentModules/ViewModule";
import Profile from "@/components/modules/UserProfile";
import routePaths from "@/utils/route-paths";

const platformRoutes = [
  {
    routePath: routePaths.HOME,
    component: <StudentModule />,
  },
  {
    routePath: routePaths.PROFILE,
    component: <Profile />,
  },
  {
    routePath: routePaths.SUBSCRIPTION_SUCCESS,
    component: <SuccessPage />,
  },
  {
    routePath: routePaths.LIBRARY,
    component: <ListLibrary />,
  },
  {
    routePath: routePaths.MODULE_DETAILS,
    component: <ViewModule />,
  },
  {
    routePath: routePaths.CONVERSATION,
    component: <Conversation />,
  },
  {
    routePath: routePaths.CONVERSATION_VIEW,
    component: <Conversation />,
  },
  {
    routePath: routePaths.NOTES_EDITOR,
    component: <Editor />,
  },
];

export default platformRoutes;
