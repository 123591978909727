import { useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import { createConversation, getMessages } from "@/api/conversation";
import constants from "@/constants";
import { goToRoute } from "@/store/actions/navigationActions";
import { appendConversationsData } from "@/store/slices/conversationSlice";
import { setToast } from "@/store/slices/toastSlice";

const useMessageHandler = (
  moduleId,
  conversationId,
  moduleDetails,
  redirect = true,
  version,
  isModel4o,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conversationCreated, setConversationCreated] = useState(false);
  const shouldScrollRef = useRef(false);
  const [conId, setConId] = useState(conversationId);
  const [qaType, setQaType] = useState("tutor");

  const fetchMessages = async ({ conversationId }) => {
    try {
      setLoading(true);
      const cId = conversationId || conId;
      if (!cId) {
        throw new Error("Conversation ID is required to fetch messages.");
      }
      const accessToken = await getAccessTokenSilently();
      const res = await getMessages(cId, accessToken);
      if (res.data) {
        const conMessages = res.data.messages;
        const transformedMessages = conMessages.map((item) => ({
          text: item.content,
          content: item.content,
          role: item.role,
          created_at: item.created_at,
          isUser: item.is_bot !== true,
          id: item.id,
          reasoning: item.reasoning ? item.reasoning : "",
          citations: item.citations ? item.citations : null,
          feedback: item.feedback ? item.feedback : null,
          qaType: item.qa_type ? item.qa_type : "tutor",
        }));

        const reverseMessages = [...transformedMessages].reverse();
        setMessages(reverseMessages);
      }
    } catch (e) {
      dispatch(
        setToast({
          message: e.response.data.error.message,
          toasterColor: "error",
        }),
      );
    } finally {
      setLoading(false);
      shouldScrollRef.current = true;
    }
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessageId = Date.now();
    const newMessage = {
      id: userMessageId,
      text: input,
      content: input,
      isUser: true,
      role: "user",
      created_at: new Date().toISOString(),
      temp_id: userMessageId,
      qaType: qaType,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput("");
    let newConversationId = conId;

    if (!conId) {
      try {
        const conPayload = {
          module_id: moduleId,
          title: moduleDetails?.title || "New Chat",
        };
        const accessToken = await getAccessTokenSilently();
        const response = await createConversation(accessToken, conPayload);
        newConversationId = response.data.conversation.id;

        dispatch(appendConversationsData(response.data.conversation));

        setConversationCreated(true);
        setConId(newConversationId);
        if (redirect) {
          const newUrl = `/module/${moduleId}/c/${newConversationId}`;
          dispatch(goToRoute(newUrl, {}, null, true));
        }
      } catch (error) {
        console.log(error);
        dispatch(
          setToast({
            message:
              error.response?.data?.error?.message ||
              "Failed to create conversation",
            toasterColor: "error",
          }),
        );
        return;
      }
    }

    const payload = {
      module_id: moduleId,
      conversation_id: newConversationId,
      question: input,
      model: isModel4o ? "4o" : "mini",
      version: version,
      qa_type: qaType,
    };

    setIsBotTyping(true);
    shouldScrollRef.current = true;

    const botMessageId = userMessageId + 1;
    let streamingMessage = {
      id: botMessageId,
      question_id: null,
      answer_id: null,
      text: "",
      content: "",
      reasoning: "",
      isUser: false,
      citations: [],
      role: "assistant",
      created_at: new Date().toISOString(),
      temp_id: botMessageId,
      qaType: qaType,
    };

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${constants.ragApi}/v1/message`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      let isFirstChunk = true;

      const updateMessagesAndScroll = (updatedMessage, isFirst = false) => {
        setMessages((prevMessages) => {
          return isFirst
            ? [...prevMessages, updatedMessage]
            : prevMessages.map((msg) =>
                msg.temp_id === botMessageId ? updatedMessage : msg,
              );
        });
        shouldScrollRef.current = true;
      };

      /* eslint-disable no-constant-condition */
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          setIsBotTyping(false);
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");
        buffer = lines.pop() || "";

        for (const line of lines) {
          if (!line.trim()) continue;

          try {
            const parsedData = JSON.parse(line);
            streamingMessage = {
              ...streamingMessage,
              id: parsedData.answer_id,
              content: parsedData.result || streamingMessage.content,
              reasoning: parsedData.reasoning || streamingMessage.reasoning,
              citations: parsedData.citations || streamingMessage.citations,
            };

            if (isFirstChunk) {
              setIsBotTyping(false);
              isFirstChunk = false;
              updateMessagesAndScroll(streamingMessage, true);
            } else {
              updateMessagesAndScroll(streamingMessage, false);
            }
          } catch (parseError) {
            console.error("Error parsing chunk:", parseError);
          }
        }
      }
    } catch (error) {
      dispatch(
        setToast({
          message:
            error.response?.data?.error?.message || "Failed to send message.",
          toasterColor: "error",
        }),
      );
    } finally {
      setIsBotTyping(false);
      if (conversationCreated) {
        setConversationCreated(false);
      }
    }
  };

  return {
    messages,
    setMessages,
    input,
    isBotTyping,
    loading,
    conversationCreated,
    setInput,
    fetchMessages,
    sendMessage,
    shouldScrollRef,
    setConId,
    conId,
    setQaType,
    qaType,
  };
};

export default useMessageHandler;
