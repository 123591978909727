import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { addNote, deleteNote, getNotes } from "@/api/notesApi.js";
import NotesEmptyState from "@/components/modules/Notes/EmptyScreen/index.jsx";
import ListNotes from "@/components/modules/Notes/ListNotes/index.jsx";
import Loader from "@/components/shared/Loader";
import AddNoteDialog from "@/components/shared/Modals/AddNoteDialog/index.jsx";
import ConfirmationDialog from "@/components/shared/Modals/ConfirmModal";
import { goToRoute } from "@/store/actions/navigationActions";
import { setToast } from "@/store/slices/toastSlice";

const Notes = ({ moduleId }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const fetchNotes = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getNotes(accessToken, moduleId);
        setNotes(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchNotes();
  }, [getAccessTokenSilently]);

  // delete module confirmed
  const handleDeleteConfirm = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await deleteNote(accessToken, deleteItemId, moduleId);
      // Update the modules state by filtering out the deleted module
      setNotes((prevModules) =>
        prevModules.filter((module) => module._id !== deleteItemId),
      );

      dispatch(
        setToast({
          message: "Note deleted successfully.",
          toasterColor: "success",
        }),
      );
    } catch (error) {
      dispatch(
        setToast({
          message:
            error.response.data.error.message || "Failed to create the note",
          toasterColor: "error",
        }),
      );
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  // Handler to view a module
  const handleViewNoteClick = (noteId) => {
    dispatch(goToRoute(`/module/${moduleId}/n/${noteId}`));
  };

  // Handler to delete a module
  const handleDeleteClick = (noteId) => {
    setDeleteItemId(noteId);
    setDeleteDialogOpen(true);
  };

  const handleAddClick = () => {
    setAddDialogOpen(true);
  };

  const handleAddNote = async (note) => {
    setAddNoteLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const payload = {
        title: note.name,
        module_id: moduleId,
      };

      const response = await addNote(accessToken, payload);

      dispatch(
        setToast({
          message: "Note Created successfully.",
          toasterColor: "success",
        }),
      );

      setAddDialogOpen(false);
      setNotes([response.data, ...notes]);

      dispatch(goToRoute(`/module/${moduleId}/n/${response.data._id}`));
    } catch (error) {
      dispatch(
        setToast({
          message:
            error.response.data.error.message || "Failed to create the module",
          toasterColor: "error",
        }),
      );
    } finally {
      setAddNoteLoading(false);
      setAddDialogOpen(false);
    }
  };

  if (loading) {
    return <Loader message="Loading notes" />;
  }

  return (
    <>
      {notes.length > 0 ? (
        <>
          <ListNotes
            items={notes}
            user={user}
            handleAddNote={handleAddClick}
            handleDeleteClick={handleDeleteClick}
            handleViewClick={handleViewNoteClick}
          />

          <ConfirmationDialog
            open={deleteDialogOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={() => {
              setDeleteDialogOpen(false);
            }}
            title="Confirm Delete"
            description="Are you sure you want to remove this Note? "
            secondaryText="This action cannot be undone. This will remove the module and any chats, materials related to it."
            loading={loading}
          />
        </>
      ) : (
        <NotesEmptyState handleClick={handleAddClick} />
      )}

      <AddNoteDialog
        open={addDialogOpen}
        handleAddNote={handleAddNote}
        handleClose={() => setAddDialogOpen(false)}
        loading={addNoteLoading}
      />
    </>
  );
};

Notes.propTypes = {
  moduleId: PropTypes.string.isRequired,
};

export default Notes;
