// StreamingDialog.jsx
import React, { useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Box,
  useTheme,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";

import { TypingIndicator } from "@/components/shared/TypingIndicator";
import constants from "@/constants";
import "./styles.css";
import { setToast } from "@/store/slices/toastSlice";

function NoteActionsDialog({ open, onClose, action, selectedText, onSave }) {
  const theme = useTheme();
  const [response, setResponse] = useState("");
  const [isBotTyping, setIsBotTyping] = useState(true);
  const [scroll, setScroll] = useState(false);

  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  // Ref to scroll the content automatically as new data comes in
  const responseEndRef = useRef(null);

  // Scroll to the bottom of the response content
  const scrollToBottom = () => {
    responseEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  // Copy the response to clipboard and show toast notification
  const handleCopy = () => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        dispatch(
          setToast({
            message: "Copied",
            toasterColor: "success",
          }),
        );
      })
      .catch(() => {
        dispatch(
          setToast({
            message: "Failed to copy",
            toasterColor: "error",
          }),
        );
      });
  };

  useEffect(() => {
    if (scroll) {
      scrollToBottom();
      setScroll(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (!open) return;

    setIsBotTyping(true);
    setResponse("");

    // Fetch and stream the response
    const fetchStreamingResponse = async () => {
      try {
        const payload = {
          context: selectedText,
          action: action,
        };
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(`${constants.ragApi}/v1/actions`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let chunkResult = "";

        /* eslint-disable no-constant-condition */
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            // setIsBotTyping(false);
            break;
          }
          chunkResult += decoder.decode(value, { stream: true });

          // Process complete lines from buffer
          const lines = chunkResult.split("\n");
          chunkResult = lines.pop() || ""; // Keep incomplete line in buffer

          for (const line of lines) {
            if (!line.trim()) continue;

            try {
              const parsedData = JSON.parse(line);
              setResponse((prev) => prev + parsedData.answer);

              setIsBotTyping(false);
              setScroll(true);
            } catch (parseError) {
              console.error("Streaming failed:", parseError);
              setResponse("An error occurred while fetching the response.");
              setIsBotTyping(false);
            } finally {
              setScroll(true);
            }
          }
        }
      } catch (error) {
        console.error("Streaming failed:", error);
        setResponse("An error occurred while fetching the response.");
      } finally {
        setIsBotTyping(false);
      }
    };

    fetchStreamingResponse();
  }, [open, action, selectedText, getAccessTokenSilently]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        borderRadius: "12px",
        isolation: "isolate",
        gap: "2px",
        padding: "2px 12px",
        opacity: 1,
        transform: "none",
      }}
    >
      <DialogTitle
        sx={{ fontWeight: "bold", fontSize: "1.25rem", padding: "8px 16px" }}
      >
        {action.charAt(0).toUpperCase() + action.slice(1)}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {/*<Typography  mb={2} sx={{ fontWeight: 500, lineHeight: 1.6  }}>*/}
        {/*    {selectedText}*/}
        {/*</Typography>*/}

        {isBotTyping ? (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <TypingIndicator
              marginTop={0}
              message={`Generating`}
              loaderType={"dotWave"}
            />
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "14px",
                backgroundColor: theme.palette.primary.main,
                marginRight: 2,
                padding: "4px",
              }}
              src={"/lrnd-chat-icon.png"}
              alt={"Bot"}
            />
            <ReactMarkdown className="reactMarkDown" skipHtml={true}>
              {response}
            </ReactMarkdown>
            <div ref={responseEndRef} /> {/* Scroll target */}
          </Box>
        )}
      </DialogContent>

      {!isBotTyping && (
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={() =>
              onSave(response, action.charAt(0).toUpperCase() + action.slice(1))
            }
            variant="outlined"
            boxShadow={1}
            startIcon={
              <SaveAltOutlinedIcon sx={{ width: "0.7em", height: "0.7em" }} />
            }
            sx={{
              backgroundColor: theme.palette.background.default,
              color: theme.palette.common.black,
              fontFamily: "'Inter', sans-serif",
              fontWeight: "bold",
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "x-small",
            }}
          >
            Save to Note
          </Button>

          <Button
            onClick={handleCopy}
            variant="outlined"
            startIcon={
              <ContentCopyIcon sx={{ width: "0.7em", height: "0.7em" }} />
            }
            sx={{
              backgroundColor: theme.palette.background.default,
              color: theme.palette.common.black,
              fontFamily: "'Inter', sans-serif",
              fontWeight: "bold",
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "x-small",
            }}
          >
            Copy
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

NoteActionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  selectedText: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NoteActionsDialog;
