// Custom Formatting Toolbar Button to toggle blue text & background color.
import {
  useBlockNoteEditor,
  useComponentsContext,
  useEditorContentOrSelectionChange,
} from "@blocknote/react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

export function ExplainButton({ onClick }) {
  const editor = useBlockNoteEditor();
  const Components = useComponentsContext();
  // Updates state on content or selection change.
  useEditorContentOrSelectionChange(() => {}, editor);

  return (
    <Components.FormattingToolbar.Button
      mainTooltip="Summarize"
      onClick={() => {
        onClick("explain");
      }}
      secondaryTooltip={
        editor.getSelectedText().length <= 500
          ? "Select at-least 500 characters"
          : ""
      }
      isDisabled={editor.getSelectedText().length <= 500}
    >
      <CreateOutlinedIcon fontSize={"small"} sx={{ marginRight: 1 }} /> Explain
    </Components.FormattingToolbar.Button>
  );
}
