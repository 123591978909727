import React, { useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ShareIcon from "@mui/icons-material/Share";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  useMediaQuery,
  Box,
  Chip,
  Alert,
  Collapse,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Import Unstable_Grid2
import PropTypes from "prop-types";

import ConfirmationDialog from "@/components/shared/Modals/ConfirmModal";
import { getLabel, getStatusColor } from "@/props-constants/TutorProps";

const ModuleToolbar = ({
  title,
  description,
  onNewChatClick,
  onEditClick,
  tutorStatus,
  onTutorActionClick,
  handleShareClick,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [chatDisabled, setChatDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const renderTutorButtonLabel = () => {
    switch (tutorStatus) {
      case "not_started":
        return "Build Tutor";
      case "ready":
      case "pending":
      case "error":
        return "Re-Build Tutor";
      case "processing":
        return "Building";
      default:
        return "Build Tutor";
    }
  };

  useEffect(() => {
    setLoading(
      !["pending", "not_started", "error", "ready"].includes(tutorStatus),
    );
    setChatDisabled(["not_started", "processing"].includes(tutorStatus));
    setSnackbarOpen(tutorStatus === "pending");
  }, [tutorStatus]);

  const handleTutorClick = () => {
    if (["ready", "pending", "error"].includes(tutorStatus)) {
      setOpen(true);
    } else {
      onTutorActionClick(tutorStatus);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Collapse in={snackbarOpen}>
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{
            maxWidth: { sm: "100%", md: "50%", lg: "50%" },
            margin: "auto",
          }}
          color="success"
        >
          Learning materials have been modified. Re-build the tutor to update
          it.
        </Alert>
      </Collapse>
      <Grid container spacing={2} alignItems="flex-start" sx={{ p: 2 }}>
        {/* Title and Edit Button */}
        <Grid xs={12} sm={8} md={8} lg={8} xl={8}>
          <Grid
            container
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid>
                <Typography variant={isSmallScreen ? "h6" : "h5"}>
                  {title}
                </Typography>
              </Grid>
              <Grid>
                <Tooltip title="Edit Module Name" placement="top">
                  <IconButton
                    onClick={onEditClick}
                    sx={{ width: "0.7em", height: "0.7em" }}
                  >
                    <Edit
                      sx={{ width: "0.7em", height: "0.7em" }}
                      color="primary"
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid>
                <Typography fontWeight={"bold"} fontSize={"small"}>
                  {" "}
                  Tutor Status:{" "}
                </Typography>
              </Grid>
              <Tooltip title={"Tutor Status"}>
                <Chip
                  label={getLabel(tutorStatus)}
                  // icon={chip.icon}
                  variant="outlined"
                  size={"small"}
                  // color={'success'}
                  sx={{
                    // backgroundColor: getStatusColor(tutorStatus, theme),
                    color: getStatusColor(tutorStatus, theme),
                    borderColor: getStatusColor(tutorStatus, theme),
                    borderRadius: "0.5rem",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    fontSize: isSmallScreen ? "0.65rem" : "0.75rem", // Responsive chip font size
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {description && (
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              sx={{ mt: 0.5, color: theme.palette.text.secondary }}
            >
              {description}
            </Typography>
          )}
        </Grid>

        {/* New Chat Button */}
        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "center", sm: "flex-end" }}
          gap={{ xs: 2, sm: 0 }}
        >
          <Tooltip
            title={
              loading
                ? "Tutor building is in progress"
                : "Build tutor to ask questions"
            }
          >
            <Box display="inline-block">
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={handleTutorClick}
                size="small"
                loading={loading} // Display loading state if tutorStatus is processing
                disabled={loading} // Disable button when processing
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  mr: 2,
                  textTransform: "none",
                  fontSize: "0.75rem",
                  borderRadius: "8px",
                }}
                loadingPosition="start"
                startIcon={
                  <BuildOutlinedIcon
                    sx={{ width: "0.85rem", height: "0.85rem" }}
                  />
                }
              >
                {renderTutorButtonLabel()}
              </LoadingButton>
            </Box>
          </Tooltip>

          <Button
            variant="contained"
            color="primary"
            onClick={onNewChatClick}
            size="small"
            startIcon={
              <AutoAwesomeIcon sx={{ width: "0.85rem", height: "0.85rem" }} />
            }
            sx={{
              width: { xs: "100%", sm: "auto" },
              fontSize: "0.75rem",
              mr: 2,
              borderRadius: "8px",
            }}
            disabled={chatDisabled}
          >
            Ask Tutor
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={
              <ShareIcon sx={{ width: "0.85rem", height: "0.85rem" }} />
            }
            onClick={handleShareClick}
            size="small"
            disableElevation
            sx={{
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "0.75rem",
              backgroundColor: "#e0f2ff",
              color: "#000",
              "&:hover": {
                backgroundColor: "#b3e5fc",
              },
            }}
            aria-label="Share the document"
          >
            Share
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          setLoading(true);
          onTutorActionClick(tutorStatus);
          setOpen(false);
        }}
        title={"Confirm Tutor Rebuild"}
        description={
          "Are you sure you want to re-build this tutor? This will recreate the tutor."
        }
        secondaryText={
          "While tutor is being built, you won't be able to ask questions or add any new learning materials to the tutor."
        }
      />
    </>
  );
};

ModuleToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onNewChatClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  tutorStatus: PropTypes.string.isRequired,
  onTutorActionClick: PropTypes.func.isRequired,
  handleShareClick: PropTypes.func.isRequired,
};

export default ModuleToolbar;
