import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const ConfirmDialog = ({
  confirmationOpen,
  handleConfirmationClose,
  handleConfirmShare,
}) => {
  return (
    <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
      <DialogTitle>Confirm Sharing</DialogTitle>
      <DialogContent>
        <Typography>
          There are unsent email addresses. Do you want to send invitations to
          these addresses?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmationClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirmShare}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
