const config = {
  envName: "production",
  lmsApi: "https://lms-api.lrnd.ai",
  lrndApi: "https://backend-api.lrnd.ai",
  domain: "auth.lrnd.ai",
  clientId: "vm7a2kTdVjdliNMsRvlfLoF4gtHRmbym",
  audience: "https://lrnd.uk.auth0.com/api/v2/",
  redirectUri: "https://app.lrnd.ai/",
  webSocketUrl: "wss://collab-api.lrnd.ai/v1/collab",
  ragApi: "https://rag-api.lrnd.ai",
};

export default config;
