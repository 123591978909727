import React, { useState } from "react";

import { Box } from "@mui/material";
import PropTypes from "prop-types";

import ListModuleBody from "@/components/modules/StudentModules/ListModules/partials/body";
import ListModulesHeader from "@/components/modules/StudentModules/ListModules/partials/header";

const ListModules = ({
  items,
  handleAddModule,
  handleChatClick,
  handleDeleteModuleClick,
  handleViewModuleClick,
  handleNewNoteClick,
  handleShareClick,
  handleRenameClick,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (newSearchText) => {
    setSearchText(newSearchText);
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        height: "95vh",
        flexDirection: "column",
      }}
      component="main"
    >
      <Box
        sx={{
          padding: 1,
          flexBasis: "10%",
          borderRadius: 2,
        }}
      >
        <ListModulesHeader
          onSearchChange={handleSearchChange}
          searchValue={searchText}
          handleAddModuleClick={handleAddModule}
        />
      </Box>

      <Box
        sx={{
          padding: 2,
          overflowY: "auto",
          flexBasis: "auto",
          flexGrow: 1,
          borderRadius: 2,
          paddingBottom: 2,
        }}
      >
        <ListModuleBody
          items={filteredItems}
          handleChatClick={handleChatClick}
          handleViewClick={handleViewModuleClick}
          handleDeleteModuleClick={handleDeleteModuleClick}
          handleNewNoteClick={handleNewNoteClick}
          handleShareClick={handleShareClick}
          handleRenameClick={handleRenameClick}
        />
      </Box>
    </Box>
  );
};

ListModules.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ListModules;
