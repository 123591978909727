import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SourceIcon from "@mui/icons-material/Source";
import SubjectIcon from "@mui/icons-material/Subject";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, styled, Tab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tabsClasses } from "@mui/material/Tabs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  buildTutor as buildTutorApi,
  getModuleDetails,
  updateModule,
} from "@/api/moduleApi";
import Notes from "@/components/modules/Notes/index.jsx";
import ConversationList from "@/components/modules/StudentModules/ViewModule/partials/ConversationsList";
import LearningMaterials from "@/components/modules/StudentModules/ViewModule/partials/LearningMaterials";
import ModuleToolbar from "@/components/modules/StudentModules/ViewModule/partials/ModuleToolbar";
import ErrorComponent from "@/components/shared/ErrorComponent";
import Loader from "@/components/shared/Loader";
import { goToRoute } from "@/store/actions/navigationActions";
import { setToast } from "@/store/slices/toastSlice";

const ShareDialog = lazy(() => import("@/components/shared/Modals/ShareModal"));

// Lazy load EditModuleDialog
const EditModuleDialog = lazy(
  () => import("@/components/shared/Modals/EditModuleDialog"),
);

const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "0.75rem",
  minWidth: 0,
  letterSpacing: 0.5,
  margin: theme.spacing(0, 2),
  padding: 0,
  overflow: "unset",
  "&:hover::before": {
    backgroundColor: "rgba(0 0 0 / 0.04)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    left: "-1rem",
    right: "-1rem",
    height: "100%",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: 0,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem",
    margin: theme.spacing(0, 1),
  },
}));

const ViewModule = () => {
  const { moduleId } = useParams();
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState("sources");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [moduleDetails, setModuleDetails] = useState(null);
  const [tutorStatus, setTutorStatus] = useState(null);

  const [sharedUsers, setSharedUsers] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchModuleDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getModuleDetails(accessToken, moduleId);
      setModuleDetails(response.data);
      setTutorStatus(response.data.tutor_status);
    } catch (error) {
      setError(
        error.response?.data?.error?.message || "Failed to load the details.",
      );
      dispatch(
        setToast({
          message:
            error.response?.data?.error?.message ||
            "Failed to load the details.",
          toasterColor: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, moduleId, dispatch]);

  useEffect(() => {
    fetchModuleDetails();
  }, [fetchModuleDetails]);

  useEffect(() => {
    if (moduleDetails && moduleDetails.tutor_status !== tutorStatus) {
      setTutorStatus(moduleDetails.tutor_status);
    }

    if (moduleDetails && moduleDetails.shared_with_details) {
      setSharedUsers(moduleDetails.shared_with_details);
    }
  }, [moduleDetails]);

  const handleTabChange = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);

  const handleEditDialogClose = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  const handleCloseDialog = () => {
    setShareDialogOpen(false);
  };
  const handleShareClick = () => {
    console.log(moduleDetails);
    setShareDialogOpen(true);
  };

  const handleEditModule = useCallback(
    async (title, description) => {
      try {
        const payload = {
          name: title,
          description: description,
        };
        const accessToken = await getAccessTokenSilently();
        const response = await updateModule(accessToken, moduleId, payload);

        setModuleDetails(response.data);
        setTutorStatus(response.data.tutor_status);

        dispatch(
          setToast({
            message: "Module updated successfully.",
            toasterColor: "success",
          }),
        );
      } catch (error) {
        dispatch(
          setToast({
            message:
              error.response.data.error.message ||
              "Failed to create the module",
            toasterColor: "error",
          }),
        );
      } finally {
        setEditDialogOpen(false);
      }
    },
    [getAccessTokenSilently, moduleId, dispatch],
  );

  const handleEdit = useCallback(() => {
    setEditDialogOpen(true);
  }, []);

  const buildTutor = useCallback(async (oldTutorStatus) => {
    setTutorStatus("processing");
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await buildTutorApi(accessToken, moduleId);

      setTutorStatus(response.data.tutor_status);
      setModuleDetails(response.data);

      dispatch(
        setToast({
          message:
            "Tutor is being build. Please refresh to check the progress.",
          toasterColor: "success",
        }),
      );
    } catch (e) {
      console.log(
        e.response?.data?.error?.message || "Failed to load the details.",
        oldTutorStatus,
      );
      dispatch(
        setToast({
          message:
            e.response?.data?.error?.message || "Failed to build the tutor",
          toasterColor: "error",
        }),
      );
      setTutorStatus(oldTutorStatus);
    }
  });

  if (loading) {
    return <Loader message="Loading module Details" />;
  }

  if (error) {
    return <ErrorComponent message={error} onRetry={fetchModuleDetails} />;
  }

  if (!moduleDetails) {
    return null; // Early return if moduleDetails are not available
  }

  return (
    <Box height="100vh">
      <ModuleToolbar
        title={moduleDetails.name || "Test Module"}
        description={moduleDetails.description || "No Description Provided."}
        onNewChatClick={() => {
          dispatch(goToRoute(`/module/${moduleId}/c`));
        }}
        onEditClick={handleEdit}
        tutorStatus={tutorStatus}
        onTutorActionClick={buildTutor}
        handleShareClick={handleShareClick}
      />

      <TabContext value={tabIndex}>
        <Box sx={{ mt: 2, borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            variant={isSmallScreen ? "scrollable" : "fullWidth"}
            scrollButtons={isSmallScreen ? "auto" : false}
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              marginLeft: 0,
              [`& .${tabsClasses.indicator}`]: {
                height: 3,
                borderTopLeftRadius: "3px",
                borderTopRightRadius: "3px",
              },
            }}
          >
            <TabItem
              icon={<SubjectIcon />}
              iconPosition="start"
              label="Notes"
              value="notes"
              disableRipple
            />
            <TabItem
              icon={<SourceIcon />}
              iconPosition="start"
              label="Learning Materials"
              value="sources"
              disableRipple
            />
            <TabItem
              icon={<QuestionAnswerIcon />}
              iconPosition="start"
              label="Chat History"
              value="conversations"
              disableRipple
            />
          </TabList>
        </Box>

        <TabPanel value="sources">
          <LearningMaterials
            moduleSources={moduleDetails.data_sources}
            moduleId={moduleId}
            setModuleDetails={setModuleDetails}
            tutorStatus={tutorStatus}
          />
        </TabPanel>
        <TabPanel value="notes">
          <Notes moduleId={moduleId} />
        </TabPanel>
        <TabPanel value="conversations">
          <ConversationList moduleId={moduleId} moduleInfo={moduleDetails} />
        </TabPanel>
      </TabContext>

      <Suspense fallback={<Loader message="Loading editor..." />}>
        <EditModuleDialog
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          onSubmit={handleEditModule}
          initialTitle={moduleDetails.name}
          initialDescription={moduleDetails.description}
        />
      </Suspense>

      <Suspense fallback={<Loader message="Loading Share dialog..." />}>
        <ShareDialog
          open={shareDialogOpen}
          onClose={handleCloseDialog}
          title={moduleDetails?.name}
          sharedList={sharedUsers}
          module={moduleDetails}
          owner={moduleDetails?.user_id === user.sub}
        />
      </Suspense>
    </Box>
  );
};

export default ViewModule;
