// actions
import { goToRoute, Logout } from "@/store/actions/navigationActions";
// utils
import routePaths from "@/utils/route-paths";

const getNavigationDrawerProps = (dispatch) => {
  const actionsList = [
    {
      icon: "dashboard",
      label: "Modules",
      goto: () => {
        dispatch(goToRoute(routePaths.HOME));
      },
      path: routePaths.HOME,
      matchPath: "module",
    },
    {
      icon: "source",
      label: "Library",
      goto: () => {
        dispatch(goToRoute(routePaths.LIBRARY));
      },
      path: routePaths.LIBRARY,
      matchPath: "library",
    },
  ];

  // User Profile and Logout actions
  const userActions = {
    profile: {
      icon: "person",
      label: "User Profile",
      goto: () => {
        console.log("profile clicked", routePaths.PROFILE);
        dispatch(goToRoute(routePaths.PROFILE));
      },
      path: routePaths.PROFILE,
      matchPath: "profile",
    },
    logout: {
      icon: "exit",
      label: "Logout",
      goto: () => {
        Logout(); // Dispatch logout action
      },
    },
  };

  return {
    actionsList,
    styleProps: {
      width: "100%",
    },
    userActions,
  };
};

export default getNavigationDrawerProps;
