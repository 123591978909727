import React, { useState } from "react";

import { Box, Divider, Popover, Typography } from "@mui/material";

const Citation = ({ number, citations, dialogRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    if (dialogRef && dialogRef.current) {
      setAnchorEl(dialogRef.current);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `citation-popover-${number}` : undefined;
  // Find the corresponding citation text
  const citation = citations.find(
    (citation) => `${citation.citation_marker}` === `${number}`,
  );
  const citationText = citation?.quote || "Citation not found";
  const citationTitle =
    citation?.file_name ||
    citation?.page_url ||
    `Citation ${citation?.citation_marker}` ||
    "Citation not found";

  return (
    <>
      <Box
        component="a"
        className={id}
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          backgroundColor: "primary.light",
          color: "primary.main",
          fontSize: "12px",
          fontWeight: 600,
          cursor: "pointer",
          mx: 0.5,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: "primary.main",
            color: "white",
          },
          lineHeight: 1,
          userSelect: "none",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {number}
      </Box>
      <Popover
        id={id}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: dialogRef ? "center" : "bottom",
          horizontal: dialogRef ? "left" : "right",
        }}
        transformOrigin={{
          vertical: dialogRef ? "center" : "top",
          horizontal: dialogRef ? "right" : "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            elevation: 4,
            sx: {
              right: dialogRef && "500px",
              left: dialogRef && "auto !important",
              maxWidth: "300px",
              // boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              backgroundColor: "white",
              borderRadius: 4,
            },
          },
        }}
        disableRestoreFocus
      >
        <Box>
          <Typography
            sx={{ px: 3, py: 1, fontSize: "0.7rem", fontWeight: "bold" }}
          >
            {" "}
            {citationTitle}
          </Typography>
          <Divider />
          <Typography
            variant="body2"
            sx={{ fontSize: "0.65rem", color: "#333", p: 3 }}
          >
            {citationText}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default Citation;
