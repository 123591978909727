import instance from "./instances/lrndBackend";

export function getNotes(accessToken, moduleId) {
  return instance(accessToken).get(`/v1/note/list/${moduleId}`);
}

export function getNoteDetails(accessToken, noteId, moduleId) {
  return instance(accessToken).get(`/v1/note/${moduleId}/d/${noteId}`);
}

export const addNote = (accessToken, payload) => {
  return instance(accessToken).post("/v1/note", payload);
};

export const deleteNote = (accessToken, noteId, moduleId) => {
  return instance(accessToken).delete(`/v1/note/${moduleId}/d/${noteId}`);
};

export const updateNote = (accessToken, noteId, payload, moduleId) => {
  return instance(accessToken).put(`/v1/note/${moduleId}/u/${noteId}`, payload);
};

export const updateNoteTitle = (accessToken, noteId, payload, moduleId) => {
  return instance(accessToken).put(
    `/v1/note/${moduleId}/u/${noteId}/title`,
    payload,
  );
};

export const getNotesVersionHistory = (accessToken, noteId) => {
  return instance(accessToken).get(`/v1/note/${noteId}/versions`);
};

export const restoreNotesVersion = (accessToken, noteId, versionId) => {
  return instance(accessToken).put(`/v1/note/${noteId}/versions/${versionId}`);
};
