import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const themeOptions = {
  palette: {
    primary: {
      main: "#1e0f69", // dark blue
      light: "#e3dcfa",
    },
    secondary: {
      main: "#0096ff", // light blue
      light: "#ebf4fa",
    },
    tertiary: {
      main: "#e66eff", // pink
      light: "#efc1fa",
    },
    common: {
      white: "#FFFFFF",
      black: "#000000",
    },

    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    gradient: {
      main: "linear-gradient(to bottom left, #e66eff 0%, #647fff 50%, #0096ff 100%)", // gradient
    },
  },
  typography: {
    fontFamily: "Gotham, Arial, sans-serif",
    h1: {
      fontFamily: "Gotham",
      fontWeight: 900, // black
    },
    h2: {
      fontFamily: "Gotham",
      fontWeight: 700, // bold
    },
    h3: {
      fontFamily: "Gotham",
      fontWeight: 400, // book
    },
    h4: {
      fontFamily: "Gotham",
      fontWeight: 300, // light
    },
    body1: {
      fontFamily: "Gotham, Arial, sans-serif",
      fontWeight: 300, // light
    },
    body2: {
      fontFamily: "Gotham, Arial, sans-serif",
      fontWeight: 400, // book
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#FFFFFF",
          // background: '#ebf4fa',
          // background: 'linear-gradient(90deg, rgb(118 174 238 / 7%) 0%, rgb(123 58 162 / 23%) 100%)'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          // background: 'linear-gradient(135deg, #76aeee, #7b3aa2)',
          borderRadius: "8px",
          // padding: '8px 16px',
          color: "#fff",
          fontWeight: "bold",

          fontSize: "0.75rem",
          // '&:hover': {
          //     background: 'linear-gradient(135deg, #7b3aa2, #76aeee)',
          // },
          textTransform: "capitalize",
        },
        textPrimary: {
          fontSize: "medium",
          textTransform: "capitalize",
        },

        containedSecondary: {
          borderRadius: "4px",
          color: "#fff",
          fontWeight: "bold",
          fontSize: "medium",
          textTransform: "capitalize",
        },
      },
    },
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export default theme;
