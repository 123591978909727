const config = {
  envName: "local",
  lmsApi: "http://127.0.0.1:5000",
  lrndApi: "http://127.0.0.1:5000",
  ragApi: "http://127.0.0.1:5002",
  domain: "dev-md7jm3mx07jp4f18.uk.auth0.com",
  clientId: "Otp6EqPHY3mOkI9kB0RKVYbsuF9YgwCh",
  audience: "https://dev-md7jm3mx07jp4f18.uk.auth0.com/api/v2/",
  redirectUri: "http://localhost:3000/",
  webSocketUrl: "ws://127.0.0.1:1234",

  stripe_public_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
};

export default config;
