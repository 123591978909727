import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux"; // Import the CSS file

import { createSubscription, upgradeSubscription } from "@/api/subscription";
import Loader from "@/components/shared/Loader"; // Import the Loader component
import getPricingPlanProps from "@/props-constants/PricingPlanProps";
import "./PricingModal.css";
import { updateSubscriptionData } from "@/store/slices/subscriptionSlice";

const PricingModal = ({
  title = "Upgrade your plan",
  openModal,
  handleCloseModal,
  closable = true,
  subscription_key = "",
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (closable && reason === "backdropClick") {
      return;
    }
    if (closable) {
      handleCloseModal();
    }
  };

  const handleClick = async (plan) => {
    const payload = {
      name: user.name,
      email: user.email,
      plan: plan.key,
      price_id:
        plan.key === "premium" ? "price_1PfMZuRoZsMwusYiSLQHcB5L" : null, // Replace with actual price ID for premium
    };

    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      let response = null;
      if (!closable) {
        response = await createSubscription(accessToken, payload);
      } else {
        response = await upgradeSubscription(accessToken, payload);
      }

      if (plan.key === "premium") {
        window.location.href = response.data.checkout_url;
      } else {
        dispatch(updateSubscriptionData(response.data));
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error creating subscription:", error);
      // Handle error accordingly
    } finally {
      setLoading(false);
    }
  };

  const pricingPlans = getPricingPlanProps(subscription_key);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="stripe-pricing-table-dialog"
      aria-describedby="stripe-pricing-table-description"
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown={true}
      classes={{ paper: closable ? "" : "blurred-backdrop" }}
    >
      {loading && <Loader message="Processing your request..." />}{" "}
      {/* Display Loader when loading */}
      {!loading && (
        <>
          <DialogTitle
            id="stripe-pricing-table-dialog"
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            {title}
          </DialogTitle>
          {closable && (
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <DialogContent dividers>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
              }}
            >
              {pricingPlans.map((plan, index) => (
                <React.Fragment key={plan.key}>
                  {index > 0 && <Divider flexItem orientation="vertical" />}
                  <Box key={plan.key} sx={{ flex: 1, p: 4 }}>
                    <Typography variant="h5" component="h1">
                      {plan.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, mt: 2, fontWeight: "medium" }}
                    >
                      {plan.price}
                    </Typography>
                    {plan.currentPlan ? (
                      <Button
                        variant="outlined"
                        disabled
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: "medium",
                        }}
                      >
                        Your current plan
                      </Button>
                    ) : (
                      <Button
                        variant={plan.buttonVariant}
                        color="secondary"
                        startIcon={
                          plan.buttonIcon ? plan.buttonIcon : undefined
                        }
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: "medium",
                        }}
                        onClick={() => handleClick(plan)}
                      >
                        {plan.buttonText}
                      </Button>
                    )}
                    <Box sx={{ mt: 8 }}>
                      {plan.features.map((feature, i) => (
                        <Box key={i} display={"flex"} mt={2}>
                          <CheckCircleOutlineIcon fontSize="small" />
                          <Typography
                            variant="body2"
                            ml={1}
                            sx={{ fontWeight: "medium" }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

PricingModal.propTypes = {
  title: PropTypes.string,
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  closable: PropTypes.bool,
};

export default PricingModal;
