import React, { useEffect, useRef } from "react";

import { Avatar, Box, Typography, useTheme } from "@mui/material";

import Loader from "@/components/shared/Loader";
import { TypingIndicator } from "@/components/shared/TypingIndicator";

import Message from "./partials/Message";

const MessageList = ({
  messages,
  isBotTyping,
  userName,
  dialogRef,
  messageLoading,
  moduleId,
  qaType,
}) => {
  const messagesEndRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isBotTyping, messagesEndRef]);

  // Helper function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();

    const isToday = now.toDateString() === date.toDateString();
    const isYesterday =
      new Date(now.setDate(now.getDate() - 1)).toDateString() ===
      date.toDateString();

    if (isToday) {
      return `Today ${date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`;
    } else if (isYesterday) {
      return `Yesterday ${date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`;
    } else {
      return (
        date.toLocaleDateString([], {
          month: "numeric",
          day: "numeric",
          year: "numeric",
        }) +
        ` ${date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`
      );
    }
  };

  if (messageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Loader size={18} message={"Loading messages"} />
      </Box>
    );
  }

  return (
    <>
      {messages.length > 0 ? (
        messages.map((message, index) => (
          <React.Fragment key={message.id || index}>
            {index === 0 ||
            new Date(messages[index - 1].created_at).toDateString() !==
              new Date(message.created_at).toDateString() ? (
              <Box width="100%" display="flex" justifyContent="center" mb={4}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  textAlign="center"
                >
                  {formatDate(message.created_at)}
                </Typography>
              </Box>
            ) : null}
            <Box
              display="flex"
              flexDirection="column"
              alignItems={message.role === "user" ? "flex-end" : "flex-start"}
            >
              <Message
                key={message.id || index}
                message={message}
                isUser={message.role === "user"}
                dialogRef={dialogRef}
                moduleId={moduleId}
              />
            </Box>
          </React.Fragment>
        ))
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          alignItems={"flex-start"}
          p={2}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              fontSize: "14px",
              backgroundColor: theme.palette.primary.main,
              marginRight: 2,
              padding: "4px",
            }}
            src={"/lrnd-chat-icon.png"}
            alt={"Bot"}
          />
          <Typography variant="body2" color="rgb(72, 71, 67)" fontWeight="bold">
            Hi {userName}, How can I help you today?
          </Typography>
        </Box>
      )}
      {/* Typing Indicator */}
      {isBotTyping && (
        <TypingIndicator
          loaderType={"dotWave"}
          message={qaType === "search" ? "Searching the web" : "Thinking"}
          messageFontSize={"0.7rem"}
          avatarSize={"24px"}
          loaderSize={18}
          loaderSpeed={0.8}
          loaderColor={"#B07BDB"}
        />
      )}
      <div ref={messagesEndRef} />
    </>
  );
};

export default React.memo(MessageList);
