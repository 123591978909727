// Custom Formatting Toolbar Button to toggle blue text & background color.
import {
  useBlockNoteEditor,
  useComponentsContext,
  useEditorContentOrSelectionChange,
} from "@blocknote/react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export function SummarizeButton({ onClick }) {
  const editor = useBlockNoteEditor();
  const Components = useComponentsContext();
  // Updates state on content or selection change.
  useEditorContentOrSelectionChange(() => {}, editor);

  return (
    <>
      <Components.FormattingToolbar.Button
        mainTooltip="Summarize"
        onClick={() => {
          onClick("summarize");
        }}
        secondaryTooltip={
          editor.getSelectedText().length <= 500
            ? "Select at-least 500 characters"
            : ""
        }
        isDisabled={editor.getSelectedText().length <= 500}
      >
        <AutoAwesomeIcon fontSize={"small"} sx={{ marginRight: 1 }} /> Summarize
      </Components.FormattingToolbar.Button>
    </>
  );
}
