//BlockNote.jsx
import { useEffect, useRef, useState } from "react";

import { BlockNoteSchema, defaultBlockSpecs } from "@blocknote/core";
import { BlockNoteView } from "@blocknote/mantine";
import {
  FormattingToolbar,
  FormattingToolbarController,
  getFormattingToolbarItems,
  useCreateBlockNote,
} from "@blocknote/react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import "./blocknoteStyle.css";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import { ExplainButton } from "@/components/modules/Notes/EditorV2/partials/ExplainButton";
import NoteActionsDialog from "@/components/shared/Modals/NoteActionsDialog";

import { SummarizeButton } from "./SummarizeButton";

const BlockNote = ({ provider, doc, user, handleDocumentChange }) => {
  const blockNoteViewRef = useRef(null); // Ref for the BlockNoteView
  const schema = BlockNoteSchema.create({
    blockSpecs: {
      ...defaultBlockSpecs,
      audio: undefined,
      image: undefined,
      video: undefined,
      file: undefined,
    },
  });

  // Track whether the current change is local
  const isLocalChange = useRef(false);

  const editor = useCreateBlockNote(
    {
      schema,
      collaboration: {
        provider: provider,
        fragment: doc.getXmlFragment("document-store"),
        user: {
          name: user.name,
          color: "#" + Math.floor(Math.random() * 16777215).toString(16),
          email: user.email,
        },
      },
    },
    [doc, provider, user],
  );

  useEffect(() => {
    if (!doc) return;

    // eslint-disable-next-line no-unused-vars
    doc.on("update", (update, origin, yDoc, tr) => {
      if (origin && origin.key && origin.key === "y-sync$") {
        isLocalChange.current = true;
      }
    });

    return () => {
      doc.off("update");
    };
  }, [doc]);

  // Call the handleDocumentChange only for local changes
  const onEditorChange = () => {
    if (isLocalChange.current) {
      handleDocumentChange(editor.document);
      isLocalChange.current = false; // Reset after handling the local change
    }
  };

  // State for managing the dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState("");
  const [selectedText, setSelectedText] = useState("");

  // Function to handle button clicks
  const handleButtonClick = (action) => {
    const text = editor.getSelectedText();
    editor.formattingToolbar.closeMenu();
    if (text.length >= 500) {
      // Ensure minimum text length
      setDialogAction(action);
      setSelectedText(text);
      setDialogOpen(true);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleSaveToNote = async (responseText, action) => {
    const blocks = await editor.tryParseMarkdownToBlocks(responseText);
    const insertedBlocks = editor.insertBlocks(
      blocks,
      editor.document[editor.document.length - 1].id,
      "after",
    );

    // Set the cursor to the last inserted block ID to trigger scroll
    if (insertedBlocks.length > 0) {
      setTimeout(() => {
        if (blockNoteViewRef.current) {
          blockNoteViewRef.current?.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        }
      }, 0); // You can adjust the delay if needed
    }

    setDialogOpen(false);
  };

  return (
    <Box
      backgroundColor={"white"}
      p={1}
      borderRadius={2}
      overflow={"auto"}
      position="relative"
      flexGrow={1}
      sx={{
        py: 4,
      }}
    >
      <BlockNoteView
        editor={editor}
        className="editorContainer"
        onChange={onEditorChange}
        formattingToolbar={false}
      >
        <FormattingToolbarController
          formattingToolbar={() => (
            <FormattingToolbar>
              {getFormattingToolbarItems()}
              {/* custom buttons */}
              <SummarizeButton onClick={handleButtonClick} />
              <ExplainButton onClick={handleButtonClick} />
            </FormattingToolbar>
          )}
        />
      </BlockNoteView>
      <div ref={blockNoteViewRef} />
      <NoteActionsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        action={dialogAction}
        selectedText={selectedText}
        onSave={handleSaveToNote}
      />
    </Box>
  );
};

BlockNote.propTypes = {
  // doc: Y.Doc,
  user: PropTypes.object.isRequired,
  provider: PropTypes.any,
  handleDocumentChange: PropTypes.func.isRequired,
};

export default BlockNote;
