import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

const Auth0Context = createContext("");

export const Auth0ContextProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <Auth0Context.Provider value={{ authToken }}>
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0Token = () => {
  return useContext(Auth0Context);
};
