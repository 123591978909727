// // EditorToolbar.jsx
import React, { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Alert,
  AlertTitle,
  Avatar,
  Menu,
  MenuItem,
  AvatarGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { RxPencil1 } from "react-icons/rx";

const EditorToolbar = ({
  noteTitle,
  setVersionHistoryOpen,
  showRefreshAlert = true,
  handleReload,
  onBackClick,
  provider,
  titleError,
  setTitleError,
  handleUpdateNoteTitle,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [onlineUsers, setOnlineUsers] = useState([]);

  // States for mobile menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // States for inline title editing
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState(noteTitle);
  // const [titleError, setTitleError] = useState(null);

  useEffect(() => {
    setTempTitle(noteTitle);
  }, [noteTitle]);

  // Listen for online users changes
  useEffect(() => {
    const updateAwarenessState = () => {
      const statesArray = Array.from(provider?.awareness.states.entries()).map(
        ([clientId, value]) => ({
          clientId,
          user: value.user,
        }),
      );
      setOnlineUsers(statesArray);
    };
    if (provider?.awareness?.states) {
      updateAwarenessState();
    }

    provider?.awareness.on("change", updateAwarenessState);

    return () => {
      provider?.awareness.off("change", updateAwarenessState);
    };
  }, [provider?.awareness]);

  const otherUsersOnline = onlineUsers.length > 1;

  const startEditingTitle = () => {
    if (!otherUsersOnline) {
      setIsEditingTitle(true);
      setTitleError(null);
    }
  };

  const handleTitleChange = (e) => {
    setTempTitle(e.target.value);
  };

  const finishEditingTitle = async () => {
    setIsEditingTitle(false);
    if (tempTitle !== noteTitle) {
      handleUpdateNoteTitle(tempTitle)
        .then(() => {})
        .catch((e) => {
          console.log(e);
          setTempTitle(noteTitle);
        });

      // try {
      //   const payload = {
      //     title: tempTitle,
      //   };
      //   const accessToken = await getAccessTokenSilently();
      //   const response = await updateNoteTitle(accessToken, noteId, payload, moduleId);
      //
      //
      //   setTitleError(null);
      // } catch (error) {
      //   setTitleError("Failed to update the title. Please try again.");
      //   setTempTitle(noteTitle);
      // }
    }
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      finishEditingTitle();
    } else if (e.key === "Escape") {
      setIsEditingTitle(false);
      setTempTitle(noteTitle);
      setTitleError(null);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        justifyContent: "space-between",
        width: "100%",
        background: "#FFF",
        p: 2,
      }}
    >
      {/* Left Section: Back and Title */}
      <Box display="flex" alignItems="center" gap={2}>
        <Tooltip title="Back to Module">
          <IconButton onClick={onBackClick} aria-label="Close version history">
            <ArrowBackIcon sx={{ width: "1em", height: "1em" }} />
          </IconButton>
        </Tooltip>

        {isEditingTitle ? (
          <TextField
            value={tempTitle}
            onChange={handleTitleChange}
            onBlur={finishEditingTitle}
            onKeyDown={handleTitleKeyDown}
            autoFocus
            variant="outlined"
            size="small"
            sx={{
              width: "200px",
              "& .MuiInputBase-input": {
                fontSize: "0.875rem",
              },
            }}
            error={Boolean(titleError)}
            helperText={titleError}
          />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              component="p"
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: isMobile ? "150px" : "250px",
              }}
              title={noteTitle}
            >
              {noteTitle || "Untitled Note"}
            </Typography>
            {!isEditingTitle && (
              <>
                {otherUsersOnline ? (
                  <Tooltip title="You cannot edit the title when multiple users are working on this note.">
                    <span>
                      <IconButton disabled sx={{ p: 0.5 }}>
                        <RxPencil1 fontSize="18px" strokeWidth="0.02em" />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Rename">
                    <IconButton onClick={startEditingTitle} sx={{ p: 0.5 }}>
                      <RxPencil1 fontSize="18px" strokeWidth="0.02em" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Box>
        )}
      </Box>

      {/* Middle Section: Refresh Alert (if needed) */}
      {showRefreshAlert && !isEditingTitle && (
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Alert
            icon={false}
            severity="success"
            action={
              <Button
                color="inherit"
                size="small"
                variant="text"
                onClick={handleReload}
                startIcon={<ReplayOutlinedIcon sx={{ fontSize: 16 }} />}
                sx={{
                  textTransform: "none",
                  textDecoration: "underline",
                }}
                aria-label="Refresh the document"
              >
                Refresh
              </Button>
            }
            sx={{
              boxShadow: 2,
              width: isMobile ? "100%" : "auto",
            }}
          >
            <AlertTitle>Refresh to see the changes</AlertTitle>
            Revert applied by another user. You are now disconnected from
            collaboration until reload.
          </Alert>
        </Box>
      )}

      {/* Right Section: Online Users & Version History */}
      <Box display="flex" alignItems="center" gap={2} sx={{ ml: "auto" }}>
        {!isMobile && (
          <>
            <AvatarGroup max={4}>
              {onlineUsers.map(({ clientId, user }) => (
                <Tooltip
                  key={clientId}
                  title={
                    <Box
                      textAlign="center"
                      display="flex"
                      flexDirection={"column"}
                    >
                      <Typography variant="caption">{user.name}</Typography>
                      <Typography variant="caption">{user.email}</Typography>
                    </Box>
                  }
                >
                  <Avatar
                    sx={{
                      bgcolor: user.color || theme.palette.primary.main,
                      width: 24,
                      height: 24,
                      fontSize: 12,
                    }}
                    alt={user?.name}
                  >
                    {user?.name?.charAt(0).toUpperCase()}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
            <Tooltip title="Version History">
              <IconButton
                onClick={() => setVersionHistoryOpen(true)}
                aria-label="Open version history"
              >
                <HistoryIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}

        {isMobile && (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="more options"
              onClick={handleMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* Online Users */}
              <MenuItem disabled>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle1">Online Users</Typography>
                  <AvatarGroup max={4}>
                    {onlineUsers.map(({ clientId, user }) => (
                      <Avatar
                        key={clientId}
                        sx={{
                          bgcolor: user.color || theme.palette.primary.main,
                          width: 24,
                          height: 24,
                          fontSize: 12,
                        }}
                        alt={user?.email}
                      >
                        {user?.email?.charAt(0).toUpperCase()}
                      </Avatar>
                    ))}
                  </AvatarGroup>
                </Box>
              </MenuItem>

              {/* Version History */}
              <MenuItem
                onClick={() => {
                  setVersionHistoryOpen(true);
                  handleMenuClose();
                }}
              >
                <HistoryIcon sx={{ marginRight: 1 }} />
                Version History
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
    </Box>
  );
};

EditorToolbar.propTypes = {
  noteTitle: PropTypes.string.isRequired,
  setVersionHistoryOpen: PropTypes.func.isRequired,
  showRefreshAlert: PropTypes.bool,
  handleReload: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  provider: PropTypes.object,
};

export default EditorToolbar;
