import instance from "./instances/subscription";

export function getSubscription(accessToken) {
  // console.log(accessToken)
  return instance(accessToken).get(`/v1/subscription`);
}

export function createSubscription(accessToken, payload) {
  return instance(accessToken).post("/v1/subscription", payload);
}

export function upgradeSubscription(accessToken, payload) {
  return instance(accessToken).post(`/v1/subscription/upgrade`, payload);
}

export function checkoutCheckoutStatus(accessToken, checkout_id) {
  return instance(accessToken).get(
    `/v1/subscription/checkout-session?session_id=${checkout_id}`,
  );
}

export function customerPortal(accessToken) {
  return instance(accessToken).get(`/v1/subscription/portal`);
}
