import { createSlice } from "@reduxjs/toolkit";

// Initial state for credit management
const initialState = {
  credits: 900, // default credit value, can be modified as per your requirements
};

const creditSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    // Directly update the credit value
    updateCredit: (state, action) => {
      state.credits = action.payload;
    },

    // Add credits to the current balance
    addCredit: (state, action) => {
      state.credits += action.payload;
    },

    // Deduct credits from the current balance
    deductCredit: (state, action) => {
      state.credits -= action.payload;
    },
  },
});

// Export the action creators
export const { updateCredit, addCredit, deductCredit } = creditSlice.actions;

// Export the reducer to be added to the store
export default creditSlice.reducer;
