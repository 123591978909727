import instance from "./instances/lrndBackend";

export const getSourceList = (accessToken) => {
  return instance(accessToken).get(`/v1/source`);
};

export const uploadFiles = (accessToken, payload) => {
  return instance(accessToken, "multipart/form-data").post(
    "v1/source/file",
    payload,
  );
};

export const addUrls = (accessToken, payload) => {
  return instance(accessToken, "application/json").post(
    "v1/source/url",
    payload,
  );
};

export const deleteSource = (accessToken, _id) => {
  return instance(accessToken).delete(`/v1/source/${_id}`);
};

export const getSignedUrl = (accessToken, _id) => {
  return instance(accessToken).get(`/v1/source/${_id}/get-signed-url`);
};
