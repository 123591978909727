import { createSlice } from "@reduxjs/toolkit";

const initialState = { sources: [], loading: false };

const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    updateSourcesData: (state, action) => {
      state.sources = action.payload;
      state.loading = false;
    },
    appendSourcesData: (state, action) => {
      state.sources = [...action.payload, ...state.sources];
    },
    removeSource: (state, action) => {
      // Filter out the source with the given ID
      state.sources = state.sources.filter(
        (source) => source._id !== action.payload,
      );
    },
    setSourceLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  updateSourcesData,
  appendSourcesData,
  removeSource,
  setSourceLoading,
} = sourceSlice.actions;
export default sourceSlice.reducer;
